import React from 'react';
import CommonPage from '../native/commonpage';
import './newpage.css'; // Import your CSS file for NewPage styling

function NewPage() {
  return (
    <div className="new-page-container">
    <div className="common-page-content">
        <CommonPage />
      </div>
      <div className="other-content">
        {/* Add your other content here */}
        {/* For example: */}
        <h2>Other Content on the Right Side</h2>
        <p>This is the content of the other side of the page.</p>
      </div>
     
    </div>
  );
}

export default NewPage;
