import React, { useState, useEffect } from "react";
import "./report-product.css";
import baseurl from "../baseurl/baseurl";
import CommonPage from "../native/commonpage";
import Loader from "../baseurl/loader";
import * as XLSX from "xlsx";

function Invoicedetails() {
  const [invoiceData, setInvoiceData] = useState([]);
  const [filteredInvoiceData, setFilteredInvoiceData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(9);

  const [searchQuery, setSearchQuery] = useState("");
  const [dateFilter, setDateFilter] = useState("Today");
  const [customStartDate, setCustomStartDate] = useState("");
  const [customEndDate, setCustomEndDate] = useState("");

  const baseUrl = baseurl.REACT_APP_BASE_URL;

  useEffect(() => {
    fetchProductData();
  }, []);

  useEffect(() => {
    handleFilter();
  }, [dateFilter, customStartDate, customEndDate]);

  const parseDate = (dateString) => {
    const date = new Date(dateString);
    return isNaN(date) ? null : date.toISOString().split("T")[0];
  };

  const fetchProductData = async () => {
    setLoading(true);
    try {
      const token = sessionStorage.getItem("token");
      if (!token) {
        throw new Error("No token found. Redirecting to login page.");
      }

      let url = `${baseUrl}/receipts/receipt`;
      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch product data");
      }

      const data = await response.json();
      console.log(data); // Log data to verify its structure

      // Ensure date fields are in a comparable format and valid
      const formattedData = data.map((invoice) => ({
        ...invoice,
        usageDate: parseDate(invoice.usageDate),
      }));

      setInvoiceData(formattedData);
      setFilteredInvoiceData(formattedData); // Set both data and filtered data initially
      handleFilter(formattedData); // Apply initial filter for today's date
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDateFilterChange = (e) => {
    setDateFilter(e.target.value);
    setCustomStartDate("");
    setCustomEndDate("");
    setError("");
  };

  const handleCustomDateChange = (setter) => (e) => {
    setter(e.target.value);
    setError("");
  };

  const handleInputChange = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);

    const filteredData = invoiceData.filter((invoice) => {
      return Object.values(invoice).some((value) => {
        if (typeof value === "string") {
          return value.toLowerCase().includes(query);
        }
        return false; // Skip non-string values
      });
    });

    setFilteredInvoiceData(filteredData);

    const indexOfItem = filteredData.findIndex((item) =>
      Object.values(item).some((value) => {
        if (typeof value === "string") {
          return value.toLowerCase().includes(query);
        }
        return false; // Skip non-string values
      })
    );

    if (indexOfItem !== -1) {
      const newPage = Math.ceil((indexOfItem + 1) / itemsPerPage);
      setCurrentPage(newPage);
    } else {
      setCurrentPage(1);
    }
  };

  const paginate = (pageNumber) => {
    if (pageNumber === "prev" && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    } else if (pageNumber === "next" && currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    } else if (pageNumber !== "..." && typeof pageNumber === "number") {
      setCurrentPage(pageNumber);
    }
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredInvoiceData.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredInvoiceData.length / itemsPerPage);

  let visiblePageNumbers = [];

  if (totalPages <= 5) {
    for (let i = 1; i <= totalPages; i++) {
      visiblePageNumbers.push(i);
    }
  } else if (currentPage <= 3) {
    for (let i = 1; i <= 5; i++) {
      visiblePageNumbers.push(i);
    }
    visiblePageNumbers.push("...");
    visiblePageNumbers.push(totalPages);
  } else if (currentPage >= totalPages - 2) {
    visiblePageNumbers.push(1);
    visiblePageNumbers.push("...");
    for (let i = totalPages - 4; i <= totalPages; i++) {
      visiblePageNumbers.push(i);
    }
  } else {
    visiblePageNumbers.push(1);
    visiblePageNumbers.push("...");
    for (let i = currentPage - 1; i <= currentPage + 1; i++) {
      visiblePageNumbers.push(i);
    }
    visiblePageNumbers.push("...");
    visiblePageNumbers.push(totalPages);
  }
  if (currentPage > 1) {
    visiblePageNumbers.unshift("prev");
  }
  if (currentPage < totalPages) {
    visiblePageNumbers.push("next");
  }

  const handleFilter = (data = invoiceData) => {
    let filteredData = data;

    if (dateFilter === "Custom" && customStartDate && customEndDate) {
      const start = parseDate(customStartDate);
      const end = parseDate(customEndDate);

      const diffTime = Math.abs(new Date(end) - new Date(start));
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      if (diffDays > 30) {
        setError("Custom date range cannot exceed 30 days.");
        return;
      }

      filteredData = data.filter((invoice) => {
        const usageDate = parseDate(invoice.usageDate);
        return usageDate && usageDate >= start && usageDate <= end;
      });
    } else if (dateFilter === "Today") {
      const today = new Date().toISOString().split("T")[0];
      filteredData = data.filter((invoice) => invoice.usageDate === today);
    } else if (dateFilter === "1 Week") {
      const today = new Date();
      const lastWeek = new Date(today.setDate(today.getDate() - 7)).toISOString().split("T")[0];
      filteredData = data.filter((invoice) => {
        const usageDate = parseDate(invoice.usageDate);
        return usageDate && usageDate >= lastWeek;
      });
    } else if (dateFilter === "15 Days") {
      const today = new Date();
      const last15Days = new Date(today.setDate(today.getDate() - 15)).toISOString().split("T")[0];
      filteredData = data.filter((invoice) => {
        const usageDate = parseDate(invoice.usageDate);
        return usageDate && usageDate >= last15Days;
      });
    }

    setFilteredInvoiceData(filteredData);
  };

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(filteredInvoiceData); // Export all filtered items
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Invoices");
    XLSX.writeFile(workbook, "invoices.xlsx");
  };

  return (
    <div className="new-page-container">
      <div className="common-page-content">
        <CommonPage />
      </div>
      <div className="other-content">
        {loading && (
          <div className="loading">
            <Loader />
          </div>
        )}
        <div>
          <div className="master-details-container">
            <div className="left-side">
              <h2>Service Report</h2>
            </div>
            <div>
              <button className="green-button" onClick={exportToExcel}>
                Export
              </button>
            </div>
            <div className="right-side">
              <div
                style={{
                  padding: "20px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "10px",
                    alignItems: "center",
                    width: "100%",
                    justifyContent: "center",
                  }}
                >
                  <select
                    value={dateFilter}
                    onChange={handleDateFilterChange}
                    style={{
                      padding: "8px",
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                      fontSize: "12px",
                      flex: "0 0 auto",
                    }}
                  >
                    <option value="Today">Today</option>
                    <option value="1 Week">1 Week</option>
                    <option value="15 Days">15 Days</option>
                    <option value="Custom">Custom Date</option>
                  </select>
                 
                  {dateFilter === "Custom" && (
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                      }}
                    >
                      <input
                        type="date"
                        value={customStartDate}
                        onChange={handleCustomDateChange(setCustomStartDate)}
                        style={{
                          padding: "8px",
                          border: "1px solid #ccc",
                          borderRadius: "4px",
                          fontSize: "12px",
                        }}
                      />
                      <input
                        type="date"
                        value={customEndDate}
                        onChange={handleCustomDateChange(setCustomEndDate)}
                        style={{
                          padding: "8px",
                          border: "1px solid #ccc",
                          borderRadius: "4px",
                          fontSize: "12px",
                        }}
                      />
                    </div>
                  )}
                  {error && (
                    <div
                      style={{
                        color: "red",
                        fontSize: "10px",
                        width: "100%",
                        textAlign: "center",
                      }}
                    >
                      {error}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="table-container">
            <table className="invoice-table">
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Business Unit</th>
                  <th>Card</th>
                  <th>Cash</th>
                  <th>Category</th>
                  <th>Center</th>
                  <th>Center Code</th>
                  <th>Check Amount</th>
                  <th>CIN</th>
                  <th>Collections Pre Tax</th>
                  <th>Custom Amount</th>
                  <th>Custome</th>
                  <th>Date</th>
                  <th>Employee Code</th>
                  <th>Employee Job Code</th>
                  <th>Employee Name</th>
                  <th>Gender</th>
                  <th>Gift Card</th>
                  <th>Gross Invoice Value</th>
                  <th>GSTIN</th>
                  <th>Guest Code</th>
                  <th>Guest Name</th>
                  <th>Invoice Center</th>
                  <th>Invoice Center Code</th>
                  <th>Invoice Date</th>
                  <th>Invoice Discount</th>
                  <th>Invoice No</th>
                  <th>Invoice Sequence No</th>
                  <th>Item</th>
                  <th>Item Code</th>
                  <th>Item Discount</th>
                  <th>Item Name</th>
                  <th>Item Quantity</th>
                  <th>Item Sale Price</th>
                  <th>Item Tags</th>
                  <th>Loyalty Points</th>
                  <th>Membership</th>
                  <th>Month</th>
                  <th>Net Invoice Value</th>
                  <th>Online Banking</th>
                  <th>PAN No</th>
                  <th>Payment No</th>
                  <th>Pre Paid Card</th>
                  <th>Receipt No</th>
                  <th>Row Num</th>
                  <th>SAC HSN</th>
                  <th>State Code</th>
                  <th>Sub Category</th>
                  <th>Tax</th>
                  <th>Total Collection</th>
                  <th>Unit Price</th>
                  <th>Zone</th>
                </tr>
              </thead>
              <tbody>
                {currentItems.map((invoice, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{invoice.businessUnit}</td>
                    <td>{invoice.card}</td>
                    <td>{invoice.cash}</td>
                    <td>{invoice.cat}</td>
                    <td>{invoice.center}</td>
                    <td>{invoice.centerCode}</td>
                    <td>{invoice.checkAmount}</td>
                    <td>{invoice.cin}</td>
                    <td>{invoice.collectionsPreTax}</td>
                    <td>{invoice.customAmount}</td>
                    <td>{invoice.custome}</td>
                    <td>{invoice.saleDate}</td>
                    <td>{invoice.employeeCode}</td>
                    <td>{invoice.employeeJobCode}</td>
                    <td>{invoice.employeeName}</td>
                    <td>{invoice.gender}</td>
                    <td>{invoice.giftCard}</td>
                    <td>{invoice.grossInvoiceValue}</td>
                    <td>{invoice.gstin}</td>
                    <td>{invoice.guestCode}</td>
                    <td>{invoice.guestName}</td>
                    <td>{invoice.invoiceCenter}</td>
                    <td>{invoice.invoiceCenterCode}</td>
                    <td>{invoice.usageDate}</td> {/* Match this field */}
                    <td>{invoice.invoiceDiscount}</td>
                    <td>{invoice.invoiceNo}</td>
                    <td>{invoice.invoiceNequenceNo}</td>
                    <td>{invoice.item}</td>
                    <td>{invoice.itemCode}</td>
                    <td>{invoice.itemDiscount}</td>
                    <td>{invoice.itemName}</td>
                    <td>{invoice.itemType}</td>
                    <td>{invoice.itemSalePrice}</td>
                    <td>{invoice.itemTags}</td>
                    <td>{invoice.loyaltyPoints}</td>
                    <td>{invoice.membership}</td>
                    <td>{invoice.month}</td>
                    <td>{invoice.netInvoiceValue}</td>
                    <td>{invoice.onlineBanking}</td>
                    <td>{invoice.panNo}</td>
                    <td>{invoice.paymentNo}</td>
                    <td>{invoice.prePaidCard}</td>
                    <td>{invoice.receiptNo}</td>
                    <td>{invoice.rowNum}</td>
                    <td>{invoice.sacHsn}</td>
                    <td>{invoice.stateCode}</td>
                    <td>{invoice.subCategory}</td>
                    <td>{invoice.tax}</td>
                    <td>{invoice.totalCollection}</td>
                    <td>{invoice.unitPrice}</td>
                    <td>{invoice.zone}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {filteredInvoiceData.length > itemsPerPage && (
            <div className="pagination">
              {visiblePageNumbers.map((number) => (
                <button
                  key={number}
                  onClick={() => paginate(number)}
                  className={
                    typeof number === "number"
                      ? currentPage === number
                        ? "active page-number"
                        : "page-number"
                      : number === "prev" || number === "next"
                      ? "prev-next"
                      : "ellipsis"
                  }
                >
                  {number}
                </button>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Invoicedetails;
