import React, { useRef, useState } from "react";
import "./invoice.css"; // Import CSS file
import { useReactToPrint } from "react-to-print";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import companyLogo from "./olova.png";
import signature from "./signature.jpg";
import baseurl from "../baseurl/baseurl";

const PrintButton = ({ handlePrint }) => {
  return (
    <div className="print-button">
      <button onClick={handlePrint}>Print</button>
    </div>
  );
};

const Invoice = ({ invoiceData, invoicetype }) => {
  const {
    totalItems,
    invoiceDate,
    invoiceNo,
    totalTaxable,
    totalSGST,
    totalCGST,
    totalAmount,
    customerName,
    customerId,
    type,
    referenceNo,
    center,
    centerName,
    totalAmountInWord,
    gender,
    zone,
    gstin,
    stateCode,
    registerAddress,
    optionpaymentOption,
    optionpaidOn,
    optionamount,
    paymentsDto,
    nonTaxableFlag,
    gstSequenceNumber,
  } = invoiceData;

  const componentRef = useRef();
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const generatePDF = () => {
    html2canvas(componentRef.current).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);

      // Convert the PDF to Blob
      const pdfBlob = pdf.output("blob");

      // Create a FormData object
      const formData = new FormData();
      formData.append("pdf", pdfBlob, "invoice.pdf");
      formData.append("email", email); // Include the email address
      formData.append("phone", phoneNumber); // Include the phone number
      formData.append("message", "Your invoice is attached."); // Optional message
      const baseUrl = baseurl.REACT_APP_BASE_URL;
      fetch(`${baseUrl}/api/send-email`, {
        method: "POST",
        body: formData,
        timeout: 30000, // 30 seconds timeout
      })
        .then((response) => {
          if (response.ok) {
            alert("Email sent successfully!");
          } else {
            alert("Failed to send email.");
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          alert("Failed to send email due to network error.");
        });
    });
  };

  const savePDF = () => {
    html2canvas(componentRef.current).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save("invoice.pdf");
    });
  };

  const reverseDate = (dateString) => {
    return dateString.split("-").reverse().join("-");
  };

  const removeRegisteredOfficePrefix = (address) => {
    const prefix = "Registered Office: ";
    if (address.startsWith(prefix)) {
      return address.substring(prefix.length).trim();
    }
    return address;
  };

  const roundToTwoDecimalPlaces = (value) => {
    return Number(Math.round(value + "e2") + "e-2");
  };

  const roundToNearestInteger = (value) => {
    return Math.round(value);
  };

  const calculateValueLoss = (originalValue, roundedValue) => {
    return originalValue - roundedValue;
  };
  const generatePDF1 = () => {
    html2canvas(componentRef.current).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);

      // Convert the PDF to Blob
      const pdfBlob = pdf.output("blob");

      // Create a FormData object
      const formData = new FormData();
      formData.append("pdf", pdfBlob, "invoice.pdf");
      formData.append("email", email); // Include the email address
      formData.append("phone", phoneNumber); // Include the phone number
      formData.append("message", "Your invoice is attached."); // Optional message
      const baseUrl = baseurl.REACT_APP_BASE_URL;

      fetch(`${baseUrl}/api/send-whatsapp`, {
        method: "POST",
        body: formData,
        timeout: 30000, // 30 seconds timeout
      })
        .then((response) => {
          if (response.ok) {
            alert("WhatsApp message sent successfully!");
          } else {
            alert("Failed to send WhatsApp message.");
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          alert("Failed to send WhatsApp message due to network error.");
        });
      
    });
  };
  return (
    <div>
      <div className="invoice-container" ref={componentRef}>
        <div className="header-con">
          <div className="company-info1">
            <span className="company-name">
              Sreyas Holistic Remedies Private Limited
            </span>
            <br />
            <span className="gstin-h">
              Registered Office : Center Point Building, 3rd floor, 359-363/301,
            </span>
            <span className="gstin-n">
              SP Road leading to New Passport Office Begumpet,
              Secunderabad,Telangana 500016
            </span>
            <span className="gstin-n">
              info@olivaclinic.com , CIN : U85110TG2008PTC061208 PAN :
              AAMCS4780L
            </span>
          </div>
          <img
            src={companyLogo}
            alt="Company Logo"
            width="230"
            height="140"
            className="company-logo"
          />
        </div>
        <div className="invoice-title">
          <h2 style={{ textAlign: "center", margin: "-47px 0 20px" }}>
            <span
              className="line-between"
              style={{
                borderBottomColor: "black", // Changed from "red" to "black"
                background: "#fff",
                padding: "0 150px",
              }}
            >
              {invoicetype === "cancel" ? "Credit Note" : "INVOICE "}
              {/* CUM RECEIPT */}
            </span>
          </h2>
        </div>
        {/* Invoice content */}

        <div className="company-details">
          <div className="company-left">
            <span className="gstin-heading">Clinic Name :</span>
            <span className="gstin-number">{centerName}</span>
            <span className="gstin-space"> </span>
            <span className="gstin-heading">Address :</span>
            <span className="gstin-number">{center}</span>
            <br />
            <span className="gstin-heading">GSTIN :</span>
            <span className="gstin-number">{gstin}</span>
          </div>

          <div className="company-right">
            <span className="gstin-heading">Invoice No :</span>
            <span className="gstin-space"> </span>
            <span className="gstin-number">{gstSequenceNumber}</span>
            <br />
            <span className="gstin-heading">Invoice Date :</span>
            <span className="gstin-space"> </span>
            <span className="gstin-number">{reverseDate(invoiceDate)}</span>
            <br />

            {type === "Service" && (
              <>
                <span className="gstin-heading">Reference No :</span>
                <span className="gstin-space"> </span>
                <span className="gstin-number">{referenceNo}</span>
                <br />
              </>
            )}
          </div>
        </div>

        <br />
        <div className="company-left">
          {/* <span className="gstin-space"> </span> */}
          <span className="gstin-number1">{customerName}</span>
          <span className="gstin-space"> </span>
          <span className="gstin-space"> </span>
          <br />
          <span className="gstin-heading2">Patient ID :</span>
          {/* <span className="gstin-space"> </span> */}
          <span className="gstin-number">{customerId}</span>
          <span className="gstin-space"> </span>
          <span className="gstin-heading2">Gender :</span>

          <span className="gstin-number">{gender} </span>

          <span className="gstin-space"> </span>

          <br />
          <br />
        </div>

        <div className="invoice-items">
          <table>
            <thead>
              <tr>
                <th>S.NO</th>
                <th>DESCRIPTION</th>
                {/* {type === "Service" && <th>Package Name</th>} */}
                <th>HSN/SAC</th>
                <th>QTY</th>
                {/* <th>Rate</th> */}
                {type === "Product" && <th>Rate</th>}
                <th>PRICE</th>
                <th>DISCOUNT</th>

                {type === "Service" ? (
                  <th>
                    NET PRICE <br></br>(Cum Tax)
                  </th>
                ) : (
                  <th>NET PRICE</th>
                )}
                <th>TAXABLE VALUE</th>
                <th>CGST</th>
                <th>SGST</th>
              </tr>
            </thead>
            <tbody>
              {totalItems.map((item, index) => (
                <tr key={index} style={{ fontSize: 13 }}>
                  <td>{index + 1}</td>
                  <td style={{ textAlign: "left" }}>{item.itemName}</td>
                  {/* {type === "Service" && <td>{item.packageNo}</td>} */}
                  <td>{item.hslSac}</td>
                  <td>{item.qty}</td>
                  {type === "Product" && (
                    <td>{formatIndianNumber(item.unitRate.toFixed(2))}</td>
                  )}
                  {/* <td>{item.unitRate}</td> */}
                  <td>{formatIndianNumber(item.totalRate.toFixed(2))}</td>
                  <td>{formatIndianNumber(item.discountPerItem.toFixed(2))}</td>
                  <td>{formatIndianNumber(item.finalPrice.toFixed(2))}</td>
                  {/* <td>{formatIndianNumber(item.taxablePrice.toFixed(2))}</td> */}
                  {type === "Service" ? (
                    <td>{formatIndianNumber(item.taxablePrice.toFixed(2))}</td>
                  ) : (
                    <td>{formatIndianNumber(item.taxablePrice.toFixed(2))}</td>
                  )}
                  <td>
                    {item.cgst.toFixed(2)}
                    {<br />}
                    {`(${item.pertax}%)`}
                  </td>
                  <td>
                    {item.sgst.toFixed(2)}
                    {<br />}
                    {`(${item.pertax}%)`}
                  </td>
                </tr>
              ))}
            </tbody>
            <tbody>
              <tr style={{ border: "none", fontSize: 13 }}>
                <td colSpan={type === "Service" ? "5" : "6"}></td>
                <td style={{ fontWeight: "bold" }}>Total</td>
                <td style={{ fontWeight: "bold" }}>
                  {formatIndianNumber(totalAmount.toFixed(2))}
                </td>

                <td style={{ fontWeight: "bold" }}>
                  {/* {formatIndianNumber(totalTaxable.toFixed(2))} */}
                  {formatIndianNumber(totalTaxable.toFixed(2))}
                </td>

                <td style={{ fontWeight: "bold" }}>
                  {formatIndianNumber(totalCGST.toFixed(2))}
                </td>
                <td style={{ fontWeight: "bold" }}>
                  {formatIndianNumber(totalSGST.toFixed(2))}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="invoice-summary">
          <span className="gstin-heading4">Taxable Value :</span>
          <span className="gstin-space"> </span>
          <span className="gstin-number4">
            ₹ {formatIndianNumber(totalTaxable.toFixed(2))}
          </span>

          <br />
          <span className="gstin-heading4">Total CGST :</span>
          <span className="gstin-space"> </span>
          <span className="gstin-number4">
            ₹ {formatIndianNumber(totalSGST.toFixed(2))}
          </span>
          <br />
          <span className="gstin-heading4">Total SGST : </span>
          <span className="gstin-space"> </span>
          <span className="gstin-number4">
            ₹ {formatIndianNumber(totalSGST.toFixed(2))}
          </span>
          <br />
          <span className="gstin-heading4">Rounding Adjustment :</span>
          <span className="gstin-space"> </span>
          <span className="gstin-number4">
            ₹{" "}
            {formatIndianNumber(
              calculateValueLoss(
                totalAmount -
                  (totalAmount - (totalTaxable + totalCGST + totalCGST)),
                roundToNearestInteger(
                  totalAmount -
                    (totalAmount - (totalTaxable + totalCGST + totalCGST))
                )
              ).toFixed(2)
            )}
          </span>
          <br />
          <span className="gstin-heading4">Net Price :</span>
          <span className="gstin-space"> </span>
          <span className="gstin-number4">
            ₹{" "}
            {formatIndianNumber(
              roundToNearestInteger(
                totalAmount -
                  (totalAmount - (totalTaxable + totalCGST + totalCGST))
              ).toFixed(2)
            )}
          </span>
          <br />
          {type === "Service" ? (
            <>
              {" "}
              <span className="gstin-heading4">Total NonTaxable :</span>
              <span className="gstin-space"> </span>
              <span className="gstin-number4">
                ₹{" "}
                {formatIndianNumber(
                  roundToNearestInteger(
                    totalAmount - (totalTaxable + totalCGST + totalCGST)
                  ).toFixed(2)
                )}
              </span>
              <br />
            </>
          ) : null}

          {type === "Service" ? (
            <h2 style={{ fontWeight: "bold", fontSize: "13px" }}>
              Total Amount : ₹{" "}
              {formatIndianNumber(
                roundToNearestInteger(totalAmount).toFixed(2)
              )}
            </h2>
          ) : null}
          <h2 style={{ fontWeight: "bold", fontSize: "12px" }}>
            (Rupees In Words: {totalAmountInWord} Only)
          </h2>
        </div>
        <div class="row">
          <div style={{ textAlign: "left" }}>
            {nonTaxableFlag === false && (
              <>
                <span
                  style={{
                    fontSize: "10px",
                    color: "#2c3e50",
                    padding: "10px",
                    margin: "0 10px 0 0", // Adjust margins for spacing
                    fontFamily: "Arial, sans-serif",
                    fontWeight: "bold",
                  }}
                >
                  Exempted Vide SL.no 74 of Notification No. 12/2017 - CT (Rate)
                  Date 28.06.2017
                </span>
              </>
            )}
          </div>
        </div>

        <div class="signature-container">
          <img
            src={signature}
            alt=""
            width="120"
            height="70"
            class="company-logo1"
          />
          <br />
        </div>
        <div style={{ textAlign: "right", marginRight: "178px" }}>
          (Signature)
        </div>
        {type === "Service" ? (
          <div>
            <h5
              style={{
                textAlign: "left",
                fontSize: "9px",
                fontWeight: "bold",
                textDecoration: "underline",
              }}
            >
              Terms & Conditions
            </h5>
            <ul style={{ textAlign: "left", fontSize: "8px" }}>
              <li>
              All treatments provided by Oliva Skin and Hair Clinic are performed
               by qualified professionals using industry-standard practices. Results may vary based on individual’s conditions.
              </li>
              <li>Payments made towards treatments are non-refundable</li>
              <li>
               In case a patient is unable to continue treatment because of medical issues, the same will be reviewed by the Medical Review Committee and only in cases of documented medical co-morbidities, a credit note may be given for the
               unutilized amount which can be utilized towards 
               customer referrals/any other packages/treatments/products etc. 
              </li>
              <li>
              Payments can be made via Cash (As per Income Tax Regulations) / 
              online transfer/Debit Card/ Credit Card/ UPI Payments/ Demand Draft/ Cheque in favor of Sreyas Holistic Remedies Private Limited. 
              In case of cheque payments, treatments will be initiated only after confirmation of payment credited to SHRPL.
              </li>
              <li>
                Oliva Skin and Hair Clinic is not responsible for any 
               adverse reactions or complications arising from undisclosed 
               health conditions or failure to follow post-treatment care instructions.
              </li>
              <li>
                 For any questions or concerns regarding the treatments availed, please contact our Clinic staff or customer support. 
              </li>
              <li>  
                  Any disputes arising from these terms and conditions or the treatments provided shall be resolved amicably. 
                  If unresolved, they will be subject to the jurisdiction of the courts in Secunderabad, Telanagana.
              </li>
              <li>
                 For further reference please retain the original invoice issued to you. 
              </li>
              <li>
                	This is a system generated invoice. 
              </li>
              <li>
              	By availing our treatments, you agree to the above terms and conditions.
               Further we reserve the right to alter/modify any terms and condition
                at any point of time without assigning any reason or intimation whatsoever.  
              </li>
            </ul>
          </div>
        ) : (
          <div>
            <h5 style={{ textAlign: "left", fontSize: "9px" }}>
              Terms & Conditions
            </h5>
            <ul style={{ textAlign: "left", fontSize: "8px" }}>
              <li>
                 Products once sold cannot be returned. Refunds are not allowed. 
              </li>
              <li>
                Exchange shall be done only in the case of defective products and has to be done within
               ten (10) days of purchase, along with a valid copy of the invoice Exchange of products is 
               only permissible at the same clinic location from where the purchase was made. In exceptional
                cases, exchanged can be given only if the product is unopened, unused, and in its original
                 packaging and within ten (10) days of purchase
              </li>
              <li>
              Please use the products as directed by our Doctors. Oliva Skin and Hair 
              Clinic and its staff is not responsible for any adverse effects due to incorrect usage or allergic reaction.
              </li>
              <li>
               Payments can be made via Cash (As per Income Tax Regulations) / 
              online transfer/Debit Card/ Credit Card/ UPI Payments/ Demand Draft/
               Cheque in favor of Sreyas Holistic Remedies Private Limited. 
               In case of cheque payments, treatments will be initiated only after confirmation of payment credited to SHRPL.
              </li>
              <li>
                Oliva Skin and Hair Clinic is not responsible for any damages or
                adverse effects arising from the use of the products beyond the
                recommended guidelines as mentioned in the prescription,
                including any adverse reactions or complications arising from
                undisclosed health conditions.
              </li>
              <li>
                For any questions or concerns regarding the products, please
                contact our Clinic staff or customer support. The products sold
                in this invoice doesn’t contravene Section 18 of Drugs and
                Cosmetic Act 1940
              </li>
              <li>
              	The products sold in this invoice doesn’t contravene Section 18 of Drugs and Cosmetic Act 1940.
                Products bought under discount/promotional schemes cannot be exchanged. 
              </li>
              <li>Any disputes arising from these terms and conditions or 
                the services provided shall be resolved amicably. 
                If unresolved, they will be subject to the jurisdiction of the courts in Secunderabad, Telanagana.</li>
              <li>
              	For further reference please retain the original invoice issued to you. 11.	This is a system generated invoice
              </li>
              <li>
                By buying our products, you agree to the above terms and conditions.
               Further we reserve the right to alter/modify any terms and condition at
                any point of time without assigning any reason or intimation whatsoever.  
              </li>
            </ul>
          </div>
        )}
        <span className="gstin-number">
          Oliva Products are Now AVAILABLE ONLINE @ www.shop.olivaclinic.com
        </span>
      </div>

      <PrintButton handlePrint={handlePrint} />
      <div className="print-button">
        <button onClick={savePDF}>Save</button>{" "}
      </div>
      <br></br>
      <div className="email-input">
        <label>Email: </label>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <div className="print-button">
        <button onClick={generatePDF}>Send Email</button>
      </div>
      <br></br>
      <div className="email-input">
      <label>Phone Number: </label>
      <input
        type="text"
        value={phoneNumber}
        onChange={(e) => setPhoneNumber(e.target.value)}
      />
    </div>
    <div className="print-button">
      <button onClick={generatePDF1}>Send WhatsApp</button>
    </div>
    </div>
  );
};

function formatIndianNumber(x) {
  const parts = x.toString().split(".");
  let number = parts[0];
  let lastThree = number.substring(number.length - 3);
  const otherNumbers = number.substring(0, number.length - 3);
  if (otherNumbers !== "") {
    lastThree = "," + lastThree;
  }
  const formattedNumber =
    otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
  return parts.length > 1 ? formattedNumber + "." + parts[1] : formattedNumber;
}

export default Invoice;
