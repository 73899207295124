// import React, { useState, useEffect } from "react";
// import "./invoicedetails.css";
// import baseurl from "../baseurl/baseurl";
// import Invoice from "./invoice";
// import CommonPage from "../native/commonpage";
// import { useLocation, useNavigate } from "react-router-dom";

// function CancelInvoicedetails() {
//   const [invoiceData, setInvoiceData] = useState([]);
//   const [error, setError] = useState(null);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [itemsPerPage, setItemsPerPage] = useState(9); // Number of items to display per page
//   const [selectedInvoice, setSelectedInvoice] = useState(null);
//   const [type, setType] = useState("");
//   const [searchQuery, setSearchQuery] = useState("");
//   const [filteredInvoiceData, setFilteredInvoiceData] = useState([]);

//   const location = useLocation();
//   const navigate = useNavigate();

//   // useEffect(() => {
//   //   fetchData();
//   // }, [currentPage, itemsPerPage]);

//   useEffect(() => {
//     if (type !== "") {
//       fetchDataByType(type); // Fetch data by type when type changes
//     }
//   }, [currentPage, itemsPerPage, type]);

//   useEffect(() => {
//     if (location.state) {
//       const parsedState = location.state.type;
//       fetchDataByType(parsedState);
//       setType(parsedState);
//     }
//   }, [location.state]);

//   const baseUrl = baseurl.REACT_APP_BASE_URL;

//   // const fetchData = async () => {
//   //   try {
//   //     const token = sessionStorage.getItem("token");
//   //     const response = await fetch(`${baseUrl}/receipts/get-all-invoice`, {
//   //       headers: {
//   //         Authorization: `Bearer ${token}`,
//   //         "Content-Type": "application/json",
//   //       },
//   //     });
//   //     if (!response.ok) {
//   //       throw new Error("Failed to fetch data");
//   //     }
//   //     const data = await response.json();
//   //     if (data.status === "success") {
//   //       setInvoiceData(data.data);
//   //     } else {
//   //       throw new Error(data.message);
//   //     }
//   //   } catch (error) {
//   //     setError(error.message);
//   //   }
//   // };

//   const AddData = async () => {
//     try {
//       const token = sessionStorage.getItem("token");
//       const response = await fetch(`${baseUrl}/receipts/collections-report`, {
//         headers: {
//           Authorization: `Bearer ${token}`,
//           "Content-Type": "application/json",
//         },
//       });
//       if (!response.ok) {
//         throw new Error("Failed to fetch data");
//       }
//       const data = await response.json();
//       if (data.status === "success") {
//         setInvoiceData(data.data);
//         fetchDataByType("Cancel");
//       } else {
//         throw new Error(data.message);
//       }
//     } catch (error) {
//       setError(error.message);
//     }
//   };

//   const fetchDataByType = async (types) => {
//     try {
//       const token = sessionStorage.getItem("token");
//       const response = await fetch(
//         `${baseUrl}/receipts/by-cancelType/${types}`,
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//             "Content-Type": "application/json",
//           },
//         }
//       );
//       if (!response.ok) {
//         throw new Error("Failed to fetch data");
//       }
//       const data = await response.json();
//       if (data.status === "success") {
//         setInvoiceData(data.data);
//       } else {
//         throw new Error(data.message);
//       }
//     } catch (error) {
//       setError(error.message);
//     }
//   };

//   const handleGenerateInvoice = async (invoiceNo, itemType) => {
//     try {
//       const token = sessionStorage.getItem("token");
//       const response = await fetch(
//         `${baseUrl}/receipts/byInvoiceNo/${invoiceNo}/type/${itemType}`,
//         {
//           method: "GET",
//           headers: {
//             Authorization: `Bearer ${token}`,
//             "Content-Type": "application/json",
//           },
//         }
//       );
//       if (!response.ok) {
//         throw new Error("Failed to fetch invoice data");
//       }
//       const invoiceData = await response.json();
//       setSelectedInvoice(invoiceData.data);
//     } catch (error) {
//       setError(error.message);
//     }
//   };

//   const openModal = (invoiceNo, itemType) => {
//     handleGenerateInvoice(invoiceNo, itemType);
//     document.getElementById("myModal").style.display = "block";
//   };

//   const closeModal = () => {
//     document.getElementById("myModal").style.display = "none";
//   };

//   const paginate = (pageNumber) => {
//     if (pageNumber === "prev" && currentPage > 1) {
//       setCurrentPage(currentPage - 1);
//     } else if (pageNumber === "next" && currentPage < totalPages) {
//       setCurrentPage(currentPage + 1);
//     } else if (pageNumber !== "..." && typeof pageNumber === "number") {
//       setCurrentPage(pageNumber);
//     }
//   };

//   // Logic to get current items
//   const indexOfLastItem = currentPage * itemsPerPage;
//   const indexOfFirstItem = indexOfLastItem - itemsPerPage;
//   // const currentItems = invoiceData.slice(indexOfFirstItem, indexOfLastItem);
//   const currentItems = filteredInvoiceData.slice(
//     indexOfFirstItem,
//     indexOfLastItem
//   );
//   const startRowNumber =
//     currentPage === 1 ? 1 : (currentPage - 1) * itemsPerPage + 1;

//   // const totalPages = Math.ceil(invoiceData.length / itemsPerPage);
//   const totalPages = Math.ceil(filteredInvoiceData.length / itemsPerPage);

//   let visiblePageNumbers = [];

//   // Calculate the middle page numbers based on the current page index
//   if (totalPages <= 5) {
//     // If there are 5 or fewer total pages, add all page numbers
//     for (let i = 1; i <= totalPages; i++) {
//       visiblePageNumbers.push(i);
//     }
//   } else if (currentPage <= 3) {
//     // If the current page is within the first 3 pages, add the first 5 pages
//     for (let i = 1; i <= 5; i++) {
//       visiblePageNumbers.push(i);
//     }
//     // Show ellipsis to indicate more pages
//     visiblePageNumbers.push("...");
//     visiblePageNumbers.push(totalPages);
//   } else if (currentPage >= totalPages - 2) {
//     // If the current page is within the last 2 pages, add the last 5 pages
//     visiblePageNumbers.push(1);
//     visiblePageNumbers.push("...");
//     for (let i = totalPages - 4; i <= totalPages; i++) {
//       visiblePageNumbers.push(i);
//     }
//   } else {
//     // If the current page is in the middle, show the current page and the previous and next 2 pages
//     visiblePageNumbers.push(1);
//     visiblePageNumbers.push("...");
//     for (let i = currentPage - 1; i <= currentPage + 1; i++) {
//       visiblePageNumbers.push(i);
//     }
//     visiblePageNumbers.push("...");
//     visiblePageNumbers.push(totalPages);
//   }

//   // Add "prev" button if not on the first page
//   if (currentPage > 1) {
//     visiblePageNumbers.unshift("prev");
//   }

//   // Add "next" button if not on the last page
//   if (currentPage < totalPages) {
//     visiblePageNumbers.push("next");
//   }
//   const handleInputChange = (event) => {
//     const query = event.target.value.toLowerCase();
//     setSearchQuery(query);

//     const filteredData = invoiceData.filter((invoice) => {
//       return Object.values(invoice).some((value) => {
//         if (typeof value === "string") {
//           return value.toLowerCase().includes(query);
//         }
//         return false; // Skip non-string values
//       });
//     });

//     setFilteredInvoiceData(filteredData);
//   };
//   return (
//     <div className="new-page-container">
//       <div className="common-page-content">
//         <CommonPage />
//       </div>
//       <div className="other-content">
//         <div>
//           {/* <h1 className="head">Invoice Details</h1> */}
//           <div className="master-details-container">
//             {/* Left side containing the name of the master */}
//             <div className="left-side">
//               <h2>Cancel Orders</h2>
//               {/* {type === "Cancel" ? <h2>Credit Invoices</h2> : <h2>Invoices</h2>} */}
//             </div>

//             {/* Right side containing search bar and buttons */}
//             <div className="right-side">
//               {/* <div className="search-bar">
//                 <input type="text" placeholder="Search..." />
//                 <button >Search</button>
//               </div> */}
//               <div className="buttons">
//               <input
//                   type="text"
//                   placeholder="Search..."
//                   value={searchQuery}
//                   onChange={handleInputChange}
//                 />
//                 <button onClick={() => fetchDataByType("Service")}>
//                   Service
//                 </button>
//                 <button onClick={() => fetchDataByType("Product")}>
//                   Product
//                 </button>
//                 <button onClick={() => AddData()}>Add</button>
//               </div>
//             </div>
//           </div>

//           <div className="navbar">
//             <table className="invoice-table">
//               <thead>
//                 <tr>
//                   <th>S.No</th>
//                   <th>Receipt No</th>
//                   <th>Guest</th>
//                   <th>Invoice No</th>
//                   <th>Item Type</th>
//                   <th>Tax Status</th>
//                   <th>Invoice Date</th>
//                   {/* <th>Generated Date</th>
//                   <th>Generated Time</th> */}
//                   <th>Action</th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {currentItems.map((invoice, index) => (
//                   <tr key={index}>
//                     <td>{startRowNumber + index}</td>
//                     <td>{invoice.receiptNo}</td>
//                     <td>{invoice.guest}</td>
//                     <td>{invoice.invoiceNo}</td>
//                     <td>{invoice.itemType}</td>
//                     <td>{invoice.taxStatus}</td>
//                     <td>{invoice.invoiceDate}</td>
//                     {/* <td>
//                       {invoice.generatedDate
//                         ? new Date(invoice.generatedDate)
//                             .toLocaleDateString("en-GB")
//                             .split("/")
//                             .reverse()
//                             .join("-")
//                         : "N/A"}
//                     </td>
//                     <td>
//                       {invoice.generatedDate
//                         ? new Date(invoice.generatedDate).toLocaleTimeString(
//                             "en-US",
//                             {
//                               hour: "numeric",
//                               minute: "numeric",
//                               hour12: true,
//                             }
//                           )
//                         : "N/A"}
//                     </td> */}
//                     <td>
//                       <button
//                       className="generate-button"
//                         // className={
//                         //   invoice.generateStatus
//                         //     ? "generated-button"
//                         //     : "generate-button"
//                         // }
//                         onClick={() =>
//                           openModal(invoice.invoiceNo, invoice.itemType)
//                         }
//                       >
//                         View
//                         {/* {invoice.generateStatus
//                           ? "Generated"
//                           : "Generate Invoice"} */}
//                       </button>
//                     </td>
//                   </tr>
//                 ))}
//               </tbody>
//             </table>
//           </div>
//           {filteredInvoiceData.length > itemsPerPage && (
//             <div className="pagination">
//               {visiblePageNumbers.map((number) => (
//                 <button
//                   key={number}
//                   onClick={() => paginate(number)}
//                   className={
//                     typeof number === "number"
//                       ? currentPage === number
//                         ? "active page-number"
//                         : "page-number"
//                       : number === "prev" || number === "next"
//                       ? "prev-next"
//                       : "ellipsis"
//                   }
//                 >
//                   {number}
//                 </button>
//               ))}
//             </div>
//           )}

//           {/* Modal */}
//           <div id="myModal" className="modal">
//             <div className="modal-content">
//               <button className="close-button" onClick={closeModal}>
//                 Back To Invoice
//               </button>
//               {selectedInvoice && (
//                 <div className="pop-box">
//                   <Invoice invoiceData={selectedInvoice} />
//                 </div>
//               )}
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default CancelInvoicedetails;

import React, { useState, useEffect } from "react";
import "./invoicedetails.css";
import baseurl from "../baseurl/baseurl";
import Invoice from "./invoice";
import CommonPage from "../native/commonpage";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../baseurl/loader";
import alertBox from "../dashboard/addcenter";

function CancelInvoicedetails() {
  const [invoiceData, setInvoiceData] = useState([]);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(9);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [type, setType] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredInvoiceData, setFilteredInvoiceData] = useState([]);
  // const [totalPages, setTotalPages] = useState(0);

  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (type !== "") {
      fetchDataByType(type);
    }
  }, [currentPage, itemsPerPage, type]);

  useEffect(() => {
    if (location.state) {
      const parsedState = location.state.type;
      fetchDataByType(parsedState);
      setType(parsedState);
    }
  }, [location.state]);

  const baseUrl = baseurl.REACT_APP_BASE_URL;
  const [dateFilter, setDateFilter] = useState("Today");
  const [customStartDate, setCustomStartDate] = useState("");
  const [customEndDate, setCustomEndDate] = useState("");
  const handleDateFilterChange = (e) => {
    setDateFilter(e.target.value);
    setCustomStartDate("");
    setCustomEndDate("");
    setError("");
  };
  const handleCustomDateChange = (setter) => (e) => {
    setter(e.target.value);
    setError("");
  };
  const fetchDataByType = async (type) => {
    setLoading(true);
    let startDate;
    let endDate = new Date().toISOString().split("T")[0];

    switch (dateFilter) {
      case "Today":
        const now = new Date();
        const startOfToday = new Date(now);
        startOfToday.setHours(0, 0, 0, 0); // Sets to midnight
        startDate = startOfToday.toISOString().split("T")[0]; // Format as YYYY-MM-DD
        endDate = now.toISOString().split("T")[0]; // Format as YYYY-MM-DD
        break;
      case "1 Week":
        startDate = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)
          .toISOString()
          .split("T")[0];
        break;
      case "15 Days":
        startDate = new Date(Date.now() - 15 * 24 * 60 * 60 * 1000)
          .toISOString()
          .split("T")[0];
        break;
      case "Custom":
        if (!customStartDate || !customEndDate) {
          alertBox(error, "Please select both start and end dates.");
          setError("Please select both start and end dates.");
          setLoading(false);
          return;
        }
        if (
          new Date(customEndDate) - new Date(customStartDate) >
          30 * 24 * 60 * 60 * 1000
        ) {
          setLoading(false);
          alertBox("error", "Date range cannot exceed 30 days.");
          setError("Date range cannot exceed 30 days.");
          return;
        }
        startDate = customStartDate;
        endDate = customEndDate;
        break;
      default:
        break;
    }

    try {
      const token = sessionStorage.getItem("token");

      if (!token) {
        alertBox("error", "No token found. Redirecting to login page.");
        setTimeout(() => {
          window.location.href = "/login";
        }, 2000);
        return;
      }

      // const centersQueryParam = `centers=${encodeURIComponent(centers)}`;
      const centers = sessionStorage.getItem("centers");
      const centersArray = centers.split("%2C");
      const centersQueryParam = centersArray
        .map((center) => `centers=${encodeURIComponent(center)}`)
        .join("&");

      const startDateParam = `startDate=${encodeURIComponent(startDate)}`;
      const endDateParam = `endDate=${encodeURIComponent(endDate)}`;
      const queryParams = `${centersQueryParam}&${startDateParam}&${endDateParam}`;

      const response = await fetch(
        `${baseUrl}/receipts/by-cancelType/${type}?${queryParams}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const data = await response.json();
      if (data.status === "success") {
        setInvoiceData(data.data);
        setFilteredInvoiceData(data.data); // Update filtered data
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false); // Hide loader after completion
    }
  };
  // const fetchDataByType = async (types) => {
  //   setLoading(true);
  //   try {
  //     const token = sessionStorage.getItem("token");
  //     const centers = sessionStorage.getItem("centers");
  //     const centersArray = centers.split("%2C");
  //     const centersQueryParam = centersArray
  //       .map((center) => `centers=${encodeURIComponent(center)}`)
  //       .join("&");

  //     // const startDateParam = `startDate=${encodeURIComponent(startDate)}`;
  //     // const endDateParam = `endDate=${encodeURIComponent(endDate)}`;
  //     const queryParams = `${centersQueryParam}`;

  //     const response = await fetch(
  //       `${baseUrl}/receipts/by-cancelType/${type}?${queryParams}`,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     );
  //     if (!response.ok) {
  //       throw new Error("Failed to fetch data");
  //     }
  //     const data = await response.json();
  //     if (data.status === "success") {
  //       setInvoiceData(data.data);
  //       setFilteredInvoiceData(data.data); // Update filtered data
  //     } else {
  //       throw new Error(data.message);
  //     }
  //   } catch (error) {
  //     setError(error.message);
  //   } finally {
  //     setLoading(false); // Hide loader after completion
  //   }
  // };

  const AddData = async () => {
    try {
      const token = sessionStorage.getItem("token");
      const response = await fetch(`${baseUrl}/receipts/collections-report`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const data = await response.json();
      if (data.status === "success") {
        setInvoiceData(data.data);
        setFilteredInvoiceData(data.data);
        fetchDataByType("All");
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      setError(error.message);
    }
  };

  const handleGenerateInvoice = async (invoiceNo, itemType, receiptNo) => {
    try {
      const token = sessionStorage.getItem("token");
      const response = await fetch(
        `${baseUrl}/receipts/byInvoiceNo/${invoiceNo}/type/${itemType}/byRecieptNo/${receiptNo}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (!response.ok) {
        throw new Error("Failed to fetch invoice data");
      }
      const invoiceData = await response.json();
      setSelectedInvoice(invoiceData.data);
    } catch (error) {
      setError(error.message);
    }
  };

  const openModal = (invoiceNo, itemType, receiptNo) => {
    handleGenerateInvoice(invoiceNo, itemType, receiptNo);
    document.getElementById("myModal").style.display = "block";
  };

  const closeModal = () => {
    document.getElementById("myModal").style.display = "none";
  };

  const handleInputChange = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);

    const filteredData = invoiceData.filter((invoice) => {
      return Object.values(invoice).some((value) => {
        if (typeof value === "string") {
          return value.toLowerCase().includes(query);
        }
        return false; // Skip non-string values
      });
    });

    setFilteredInvoiceData(filteredData);

    // Calculate the new current page based on the index of the searched item
    const indexOfItem = filteredData.findIndex((item) =>
      Object.values(item).some((value) => value.toLowerCase().includes(query))
    );
    if (indexOfItem !== -1) {
      const newPage = Math.ceil((indexOfItem + 1) / itemsPerPage);
      setCurrentPage(newPage);
    } else {
      // If the item is not found, reset current page to 1
      setCurrentPage(1);
    }
  };

  const paginate = (pageNumber) => {
    if (pageNumber === "prev" && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    } else if (pageNumber === "next" && currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    } else if (pageNumber !== "..." && typeof pageNumber === "number") {
      setCurrentPage(pageNumber);
    }
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredInvoiceData.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const startRowNumber =
    currentPage === 1 ? 1 : (currentPage - 1) * itemsPerPage + 1;

  const totalPages = Math.ceil(filteredInvoiceData.length / itemsPerPage);

  let visiblePageNumbers = [];

  if (totalPages <= 5) {
    for (let i = 1; i <= totalPages; i++) {
      visiblePageNumbers.push(i);
    }
  } else if (currentPage <= 3) {
    for (let i = 1; i <= 5; i++) {
      visiblePageNumbers.push(i);
    }
    visiblePageNumbers.push("...");
    visiblePageNumbers.push(totalPages);
  } else if (currentPage >= totalPages - 2) {
    visiblePageNumbers.push(1);
    visiblePageNumbers.push("...");
    for (let i = totalPages - 4; i <= totalPages; i++) {
      visiblePageNumbers.push(i);
    }
  } else {
    visiblePageNumbers.push(1);
    visiblePageNumbers.push("...");
    for (let i = currentPage - 1; i <= currentPage + 1; i++) {
      visiblePageNumbers.push(i);
    }
    visiblePageNumbers.push("...");
    visiblePageNumbers.push(totalPages);
  }

  if (currentPage > 1) {
    visiblePageNumbers.unshift("prev");
  }

  if (currentPage < totalPages) {
    visiblePageNumbers.push("next");
  }

  const reverseDate = (dateString) => {
    return dateString.split("-").reverse().join("-");
  };

  return (
    <div className="new-page-container">
      <div className="common-page-content">
        <CommonPage />
      </div>
      <div className="other-content">
        {loading && (
          <div className="loading">
            <Loader />
          </div>
        )}
        <div>
          <div className="master-details-container">
            <div className="left-side">
              <h2>Cancelled Invoices</h2>
            </div>
            <div className="right-side">
            <div
                style={{
                  padding: "20px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "10px",
                    alignItems: "center",
                    width: "100%",
                    justifyContent: "center",
                  }}
                >
                  <select
                    value={dateFilter}
                    onChange={handleDateFilterChange}
                    style={{
                      padding: "8px",
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                      fontSize: "12px",
                    }}
                  >
                    <option value="Today">Today</option>
                    <option value="1 Week">1 Week</option>
                    <option value="15 Days">15 Days</option>
                    <option value="Custom">Custom Date</option>
                  </select>
                  {dateFilter === "Custom" && (
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                      }}
                    >
                      <input
                        type="date"
                        value={customStartDate}
                        onChange={handleCustomDateChange(setCustomStartDate)}
                        style={{
                          padding: "8px",
                          border: "1px solid #ccc",
                          borderRadius: "4px",
                          fontSize: "12px",
                        }}
                      />
                      <input
                        type="date"
                        value={customEndDate}
                        onChange={handleCustomDateChange(setCustomEndDate)}
                        style={{
                          padding: "5px",
                          border: "1px solid #ccc",
                          borderRadius: "2px",
                          fontSize: "10px",
                        }}
                      />
                    </div>
                  )}
                  {error && (
                    <div
                      style={{
                        color: "red",
                        fontSize: "10px",
                        width: "100%",
                        textAlign: "center",
                      }}
                    >
                      {error}
                    </div>
                  )}
                </div>
              </div>
              <div className="buttons">
                <input
                  type="text"
                  placeholder="Search..."
                  value={searchQuery}
                  onChange={handleInputChange}
                />
                <button onClick={() => fetchDataByType("Service")}>
                  Service
                </button>
                <button onClick={() => fetchDataByType("Product")}>
                  Product
                </button>
                {/* <button onClick={AddData}>Add</button> */}
              </div>
            </div>
          </div>
          <div className="navbar">
            <table className="invoice-table">
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Receipt No</th>
                  <th>Guest</th>
                  <th>Invoice No</th>
                  <th>Item Type</th>
                  <th>Tax Status</th>
                  <th>Invoice Date</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {currentItems.map((invoice, index) => (
                  <tr key={index}>
                    <td>{startRowNumber + index}</td>
                    <td>{invoice.receiptNo}</td>
                    <td>{invoice.guest}</td>
                    <td>{invoice.invoiceNo}</td>
                    <td>{invoice.itemType}</td>
                    <td>{invoice.taxStatus}</td>
                    <td>{reverseDate(invoice.invoiceDate)}</td>
                    <td>
                      <button
                        className="generate-button"
                        onClick={() =>
                          openModal(
                            invoice.invoiceNo,
                            invoice.itemType,
                            invoice.receiptNo
                          )
                        }
                      >
                        View
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {filteredInvoiceData.length > itemsPerPage && (
            <div className="pagination">
              {visiblePageNumbers.map((number, idx) => (
                <button
                  key={idx}
                  onClick={() => paginate(number)}
                  className={
                    typeof number === "number"
                      ? currentPage === number
                        ? "active page-number"
                        : "page-number"
                      : number === "prev" || number === "next"
                      ? "prev-next"
                      : "ellipsis"
                  }
                >
                  {number}
                </button>
              ))}
            </div>
          )}
          <div id="myModal" className="modal">
            <div className="modal-content">
              <button className="close-button" onClick={closeModal}>
                Back To Invoice
              </button>
              {selectedInvoice && (
                <div className="pop-box">
                  <Invoice
                    invoiceData={selectedInvoice}
                    invoicetype={"cancel"}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CancelInvoicedetails;
