// Login.js

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../images/logo2.png";
import userIcon from "../../images/user-icon.png";
import passwordIcon from "../../images/password-icon.png";
import passwordIcon2 from "../../images/password-icon2.png";
import forgotPasswordImage from "../../images/forgot-password.jpg";
import baseurl from "../baseurl/baseurl";
import "./login.css"; // Import the custom CSS file
import loginVectorImage from "../../images/login-vector.jpg";
import alertBox from "../dashboard/addcenter";
import Loader from "../baseurl/loader";

function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(null);
  const baseUrl = baseurl.REACT_APP_BASE_URL;
  const [loading, setLoading] = useState(false);

  const isLoggedIn = () => {
    return sessionStorage.getItem("token") !== null;
  };

  if (isLoggedIn()) {
    navigate("/dashboard");
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true); // Show loader
    try {
      const requestBody = JSON.stringify({
        username: email,
        password: password,
      });

      const response = await fetch(`${baseUrl}/authenticate`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: requestBody,
      });

      if (!response.ok) {
        setLoading(false); 
        if (response.status === 401) {
          alertBox("error", "Invalid credentials!!");
          setTimeout(() => {
            window.location.href = "/login";
          }, 2000);
          return;
        } else if (response.status >= 500) {
          alertBox(
            "error",
            "Server is currently unavailable. Please try again later."
          );
          throw new Error(
            "Server is currently unavailable. Please try again later."
          );
        } else {
          alertBox(
            "error",
            "Failed to fetch data. Please check your network connection and try again."
          );
          throw new Error(
            "Failed to fetch data. Please check your network connection and try again."
          );
        }
      }

      alertBox("success", "Successful Login!!");
      // const { token } = await response.json();
      const data = await response.json();
      console.log("data is :",data.centers);
      sessionStorage.setItem("token", data.token);
      sessionStorage.setItem("username", email);
      sessionStorage.setItem("centers",data.centers)
      navigate("/dashboard");
    } catch (error) {
      if (
        error.name === "TypeError" &&
        error.message.includes("Failed to fetch")
      ) {
        alertBox(
          "error",
          "Server is currently unavailable. Please try again later."
        );
        setError("Server is currently unavailable. Please try again later.");
      } else {
        alertBox("error", error.message);
        setError(error.message);
      }
    } finally {
      setLoading(false); 
    }
  };

  return (
    <div>
      {loading && <div className="loading"><Loader /></div>}

      <div className="login-container">
        <div className="left-container">
          <img
            src={loginVectorImage}
            alt=""
            style={{ maxWidth: "100%", maxHeight: "440px", marginTop: "20vh" }}
          />
        </div>
        <div className="right-container">
          <div class="form-container">
            <div className="header-logo">
              <a href="#">
                <img src={logo} alt="Logo" />
              </a>
            </div>
            <div class="form-header">
              <h1>Log in to continue</h1>
              <p>
                Please enter your email and password below to access the portal.
              </p>
            </div>
            <div class="form-body">
              <div class="form-group-login">
                <label for="email">Email ID</label>
                <div class="input-group">
                  <input
                    type="text"
                    id="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  <div class="input-addon">
                    <img src={userIcon} alt="" />
                  </div>
                </div>
              </div>
              <div class="form-group-login">
                <label for="password">Password</label>
                <div class="input-group">
                  <input
                    type={showPassword ? "text" : "password"}
                    id="password"
                    placeholder="********"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                  <div
                    class="input-addon"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    <img
                      src={showPassword ? passwordIcon2 : passwordIcon}
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div class="form-group-login">
                <button type="submit" class="common-btn" onClick={handleSubmit}>
                  Continue
                </button>
                <a href="/forgot-password" class="forgot-txt">
                  Forgot password?
                </a>
              </div>
              {/* <div class="form-group">
                <a href="forgot-password.html" class="forgot-txt">Forgot password?</a>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      {/* <div className="copyright">
        <span>
          © 2024 Sadguru Healthcare Services Pvt Ltd | All rights reserved.
        </span>
      </div> */}
    </div>
  );
}

export default Login;
