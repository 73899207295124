import React, { useState, useEffect } from 'react';
import CanvasJSReact from '@canvasjs/react-charts';

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const ProductChart = () => {
    const [selectedDuration, setSelectedDuration] = useState(7); // Default to 7 days
    const [filteredData, setFilteredData] = useState([]);

    // Dummy data for the last 15 days
    const dummyData = [
        { "date": "Apr 01", "sales": 15 },
        { "date": "Apr 02", "sales": 20 },
        { "date": "Apr 03", "sales": 18 },
        { "date": "Apr 04", "sales": 22 },
        { "date": "Apr 05", "sales": 25 },
        { "date": "Apr 06", "sales": 30 },
        { "date": "Apr 07", "sales": 28 },
        // Add more data for 10 days as needed
        { "date": "Apr 08", "sales": 35 },
        // Add more data for 15 days as needed
        { "date": "Apr 09", "sales": 38 },
        { "date": "Apr 10", "sales": 42 },
        { "date": "Apr 11", "sales": 40 },
        { "date": "Apr 12", "sales": 45 },
        { "date": "Apr 13", "sales": 50 },
        { "date": "Apr 14", "sales": 48 },
        { "date": "Apr 15", "sales": 55 },
    ];

    useEffect(() => {
        // Filter data based on the selected duration
        const currentDate = new Date();
        const filteredData = dummyData.filter(item => {
            const itemDate = new Date(item.date + ', ' + currentDate.getFullYear());
            const diffTime = Math.abs(currentDate - itemDate);
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            return diffDays <= selectedDuration;
        });
        setFilteredData(filteredData);
    }, [selectedDuration]);

    // Chart options
    const options = {
        theme: "light2",
        title: {
            text: "Product Sales for the Last " + selectedDuration + " Days"
        },
        axisX: {
            title: "Date",
            interval: 1,
            labelAngle: -45,
            labelFormatter: function (e) {
                return filteredData[e.value].date;
            }
        },
        axisY: {
            title: "Sales"
        },
        data: [{
            type: "line",
            dataPoints: filteredData.map(item => ({ x: new Date(item.date + ', ' + new Date().getFullYear()), y: item.sales }))
        }]
    };

    return (
        <div>
            <CanvasJSChart options={options} />
            {/* Add buttons or select dropdown to change the selected duration */}
        </div>
    );
};

export default ProductChart;
