import React, { Component } from "react";
import CanvasJSReact from "@canvasjs/react-charts";
import "../invoice/invoicedetails.css";

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

class LineGraph extends Component {
  render() {
    const options = {
      width: 1000, // Increase the width of the chart
      animationEnabled: true,
      exportEnabled: true,
      theme: "light2",
      watermark: {
        showWatermark: false // This removes the watermark
      },
      title: {
        text: "Product and Service Sales",
      },
      axisY: {
        includeZero: true,
      },
      data: [
        {
          type: "column",
          name: "Product Sales",
          indexLabelFontColor: "#5A5757",
          indexLabelPlacement: "outside",
          dataPoints: [
            { x: 10, y: 71 },
            { x: 20, y: 55 },
            { x: 30, y: 50 },
            { x: 40, y: 65 },
            { x: 50, y: 71 },
            { x: 60, y: 68 },
            { x: 70, y: 38 },
            { x: 80, y: 92, indexLabel: "Highest" },
            { x: 90, y: 54 },
            { x: 100, y: 60 },
            { x: 110, y: 21 },
            { x: 120, y: 49 },
            { x: 130, y: 36 },
          ],
        },
        {
          type: "column",
          name: "Service Sales",
          indexLabelFontColor: "#5A5757",
          indexLabelPlacement: "outside",
          dataPoints: [
            { x: 10, y: 40 },
            { x: 20, y: 45 },
            { x: 30, y: 30 },
            { x: 40, y: 55 },
            { x: 50, y: 41 },
            { x: 60, y: 38 },
            { x: 70, y: 28 },
            { x: 80, y: 72, indexLabel: "Highest" },
            { x: 90, y: 34 },
            { x: 100, y: 50 },
            { x: 110, y: 31 },
            { x: 120, y: 39 },
            { x: 130, y: 46 },
          ],
        },
      ],
    };

    return (
      <div>
        <CanvasJSChart options={options} />
        <div className="container">
          <div className="box-with-border">Product</div>
          <div className="box-product"></div>
          <div className="box-with-border">Service</div>
          <div className="box-service"></div>
        </div>
      </div>
    );
  }
}

export default LineGraph;
