import React, { useState, useEffect } from "react";
import "../invoice/invoicedetails.css";
import baseurl from "../baseurl/baseurl";
import { FaRegPenToSquare } from "react-icons/fa6";
import CommonPage from "../native/commonpage";
import alertBox from "./addcenter";
import Loader from "../baseurl/loader";

function Service() {
  const [invoiceData, setInvoiceData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(8);
  const [searchTerm, setSearchTerm] = useState("");
  const [center, setcurrentcenter] = useState("");
  const baseUrl = baseurl.REACT_APP_BASE_URL;
  const [loading, setLoading] = useState(false);
  const [filteredInvoiceData, setFilteredInvoiceData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const [formData, setFormData] = useState({
    taxType: "", // Initialize with an empty string
    hsnCode: "",
  });

  // Update formData when center changes
  useEffect(() => {
    if (center) {
      setFormData({
        ...formData,
        taxType: center.taxType || "", // Update centerName with center.taxType
        hsnCode: center.hsnCode || "",
      });
    }
  }, [center]); // Make sure to include dependencies here

  // Function to handle changes in form inputs
  const handleInputChange = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);

    const filteredData = invoiceData.filter((invoice) => {
      return Object.values(invoice).some((value) => {
        if (typeof value === "string") {
          return value.toLowerCase().includes(query);
        }
        return false; // Skip non-string values
      });
    });

    setFilteredInvoiceData(filteredData);

    // Calculate the new current page based on the index of the searched item
    const indexOfItem = filteredData.findIndex((item) =>
      Object.values(item).some((value) => {
        if (typeof value === "string") {
          return value.toLowerCase().includes(query);
        }
        return false; // Skip non-string values
      })
    );

    // setFilteredInvoiceData(filteredData);

    // Calculate the new current page based on the index of the searched item
    // const indexOfItem = filteredData.findIndex((item) =>
    //   Object.values(item).some((value) => value.toLowerCase().includes(query))
    // );
    if (indexOfItem !== -1) {
      const newPage = Math.ceil((indexOfItem + 1) / itemsPerPage);
      setCurrentPage(newPage);
    } else {
      // If the item is not found, reset current page to 1
      setCurrentPage(1);
    }
  };

  useEffect(() => {
    if (center) {
      setFormData({
        taxType: center.taxType || "",
        hsnCode: center.hsnCode || "",
      });
    }
  }, [center]);

  const clearFormData = () => {
    setFormData({
      taxType: "",
      hsnCode: "",
    });
  };

  const paginate = (pageNumber) => {
    if (pageNumber === "prev" && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    } else if (pageNumber === "next" && currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    } else if (pageNumber !== "..." && typeof pageNumber === "number") {
      setCurrentPage(pageNumber);
    }
  };

  // Logic to get current items
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredInvoiceData.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const startRowNumber =
    currentPage === 1 ? 1 : (currentPage - 1) * itemsPerPage + 1;

  const totalPages = Math.ceil(filteredInvoiceData.length / itemsPerPage);

  let visiblePageNumbers = [];

  if (totalPages <= 5) {
    for (let i = 1; i <= totalPages; i++) {
      visiblePageNumbers.push(i);
    }
  } else if (currentPage <= 3) {
    for (let i = 1; i <= 5; i++) {
      visiblePageNumbers.push(i);
    }
    visiblePageNumbers.push("...");
    visiblePageNumbers.push(totalPages);
  } else if (currentPage >= totalPages - 2) {
    visiblePageNumbers.push(1);
    visiblePageNumbers.push("...");
    for (let i = totalPages - 4; i <= totalPages; i++) {
      visiblePageNumbers.push(i);
    }
  } else {
    visiblePageNumbers.push(1);
    visiblePageNumbers.push("...");
    for (let i = currentPage - 1; i <= currentPage + 1; i++) {
      visiblePageNumbers.push(i);
    }
    visiblePageNumbers.push("...");
    visiblePageNumbers.push(totalPages);
  }

  if (currentPage > 1) {
    visiblePageNumbers.unshift("prev");
  }

  if (currentPage < totalPages) {
    visiblePageNumbers.push("next");
  }

  // Add "prev" button if not on the first page
  if (currentPage > 1) {
    visiblePageNumbers.unshift("prev");
  }

  // Add "next" button if not on the last page
  if (currentPage < totalPages) {
    visiblePageNumbers.push("next");
  }

  useEffect(() => {
    fetchData();
  }, [currentPage, itemsPerPage]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const token = sessionStorage.getItem("token");
      const response = await fetch(`${baseUrl}/services`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        setLoading(false);
        alertBox("error", "Failed to fetch data");
        throw new Error("Failed to fetch data");
      }

      const data = await response.json();
      if (data.status === "success" && data.data) {
        alertBox("success", "Successful fetch data!!");
        console.log(data.data);
        setInvoiceData(data.data);
        // setInvoiceData(data.data);
        setFilteredInvoiceData(data.data); 
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      console.error("Error:", error.message);
    } finally {
      setLoading(false); // Hide loader after completion
    }
  };

  // const handleInputChange = (e) => {
  //   setSearchTerm(e.target.value);
  // };

  const openModal = () => {
    setcurrentcenter("");
    document.getElementById("myModal").style.display = "block";
  };

  const closeModal = () => {
    document.getElementById("myModal").style.display = "none";
    clearFormData();
  };

  const updateModal = (invoice) => {
    setcurrentcenter(invoice);
    document.getElementById("myupdateModal").style.display = "block";
    setFormData({
      taxType: invoice.taxType,
      hsnCode: invoice.hsnCode,
    });
  };

  const closeupdateModal = () => {
    document.getElementById("myupdateModal").style.display = "none";
    setcurrentcenter("");
    clearFormData();
  };

  // Method to handle update operation

  const handleFaRegPenToSquareClick = async () => {
    try {
      const token = sessionStorage.getItem("token");
      const response = await fetch(`${baseUrl}/services/update/${center.id}`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        const errorResponse = await response.json();
        throw new Error(errorResponse.message);
      }

      const data = await response.json();
      document.getElementById("myModal").style.display = "none";
      alertBox("success", "Successfully Update");

      // Reset form data
      clearFormData();
      closeupdateModal();
    } catch (error) {
      console.error("Error:", error.message);
      alert("Error:", error.message);
    }
  };

  const addProduct = async () => {
    try {
      const token = sessionStorage.getItem("token");
      const response = await fetch(`${baseUrl}/services/add`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      console.log(response);
      if (!response.ok) {
        const errorResponse = await response.json();
        throw new Error(errorResponse.message);
      }

      const data = await response.json();
      document.getElementById("myModal").style.display = "none";
      alertBox("success", "Successfully saved");

      // Reset form data
      clearFormData();
    } catch (error) {
      console.error("Error:", error.message);
      alert("Error:", error.message);
    }
  };

  return (
    <div className="new-page-container">
      <div className="common-page-content">
        <CommonPage />
      </div>
      <div className="other-content">
        {loading && (
          <div className="loading">
            <Loader />
          </div>
        )}
        <div className="master-details-container">
          <div className="left-side">
            <h2>Service Details</h2>
          </div>
          <div className="right-side">
            <div className="buttons">
              <input
                type="text"
                placeholder="Search..."
                value={searchQuery}
                onChange={handleInputChange}
              />
              <button className="generate-button" onClick={openModal}>
                Add
              </button>
            </div>
          </div>
        </div>
        <div className="navbar">
          <table className="invoice-table">
            <thead>
              <tr>
                <th>Service Id</th>
                <th>Service Code</th>
                <th>Service Name</th>
                <th>Bracket Name</th>
                <th>Taxable/NonTaxable</th>

                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((invoice, index) => (
                <tr key={index}>
                  <td>{invoice.id}</td>
                  <td>{invoice.serviceCode}</td>
                  <td>{invoice.serviceName}</td>
                  <td>{invoice.bracketName}</td>
                  <td>{invoice.tnt === "T" ? "TAXABLE" : "NONTAXABLE"}</td>
                  <td>
                    <div className="icon-container">
                      <FaRegPenToSquare
                        className="icon green"
                        onClick={() => updateModal(invoice)}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {filteredInvoiceData.length > itemsPerPage && (
          <div className="pagination">
            {visiblePageNumbers.map((number) => (
              <button
                key={number}
                onClick={() => paginate(number)}
                className={
                  typeof number === "number"
                    ? currentPage === number
                      ? "active page-number"
                      : "page-number"
                    : number === "prev" || number === "next"
                    ? "prev-next"
                    : "ellipsis"
                }
              >
                {number}
              </button>
            ))}
          </div>
        )}
      </div>

      <div id="myModal" className="modal">
        <div className="modal-content-center">
          <button className="close-button-center" onClick={closeModal}>
            X
          </button>
          <div className="add-center-form-container">
            <div className="form-heading">
              <span className="form-heading-tax">Add Service</span>
            </div>
            <form className="form-inline">
              <div className="form-group">
                <label htmlFor="centerName">Select Center Type</label>
                <select
                  id="taxType"
                  name="taxType"
                  value={formData.taxType}
                  onChange={handleInputChange}
                  required
                  className="form-input"
                >
                  <option value="">Select Center Type</option>
                  <option value="TAXABLE">TAXABLE</option>
                  <option value="NON_TAXABLE">NON_TAXABLE</option>
                </select>
              </div>

              <div className="form-group">
                <label htmlFor="centerName">HSN Code</label>
                <input
                  type="text"
                  id="hsnCode"
                  name="hsnCode"
                  placeholder="HSN Code"
                  value={formData.hsnCode}
                  onChange={handleInputChange}
                  required
                  className="form-input"
                />
              </div>

              <button
                type="button"
                className="submit-button"
                onClick={addProduct}
              >
                Add
              </button>
            </form>
          </div>
        </div>
      </div>
      <div id="myupdateModal" className="modal">
        <div className="modal-content-center">
          <button className="close-button-center" onClick={closeupdateModal}>
            X
          </button>
          <div className="add-center-form-container">
            <div className="form-heading">
              <span className="form-heading-tax">Update Service</span>
            </div>
            <form className="form-inline">
              <div className="form-group">
                <label htmlFor="centerName">Select Center Type</label>
                <select
                  id="taxType"
                  name="taxType"
                  value={formData.taxType}
                  onChange={handleInputChange}
                  required
                  className="form-input"
                >
                  <option value="">Select Center Type</option>
                  <option value="TAXABLE">TAXABLE</option>
                  <option value="NON_TAXABLE">NON_TAXABLE</option>
                </select>
              </div>

              <div className="form-group">
                <label htmlFor="centerName">HSN Code</label>
                <input
                  type="text"
                  id="hsnCode"
                  name="hsnCode"
                  placeholder="HSN Code"
                  value={formData.hsnCode}
                  onChange={handleInputChange}
                  required
                  className="form-input"
                />
              </div>

              <button
                type="button"
                className="submit-button"
                onClick={handleFaRegPenToSquareClick}
              >
                Update
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Service;
