import React, { useState, useEffect } from "react";
import "../invoice/invoicedetails.css";
import baseurl from "../baseurl/baseurl";
import CommonPage from "../native/commonpage";
import { MdDelete } from "react-icons/md";
import { BiShow } from "react-icons/bi";
import { FaRegPenToSquare } from "react-icons/fa6";
import alertBox from "./addcenter";

function Center() {
  const [invoiceData, setInvoiceData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(6);
  const [searchTerm, setSearchTerm] = useState("");
  const [center, setcurrentcenter] = useState("");
  const [error, setError] = useState(null);


  useEffect(() => {
    fetchData();
  }, [currentPage, itemsPerPage]);

  const baseUrl = baseurl.REACT_APP_BASE_URL;

  const fetchData = async () => {
    try {
      const token = sessionStorage.getItem("token");
      const response = await fetch(`${baseUrl}/centers`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const data = await response.json();
      if (data.status === "success") {
        setInvoiceData(data.data);
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = invoiceData
    .filter((item) =>
      item.centerName.toLowerCase().includes(searchTerm.toLowerCase())
    ) // Filter items based on search term
    .slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(
    invoiceData.filter((item) =>
      item.centerName.toLowerCase().includes(searchTerm.toLowerCase())
    ).length / itemsPerPage
  );

  // Generate visible page numbers based on current page and total pages
  let visiblePageNumbers = [];
  if (totalPages <= 5) {
    for (let i = 1; i <= totalPages; i++) {
      visiblePageNumbers.push(i);
    }
  } else if (currentPage <= 3) {
    for (let i = 1; i <= 5; i++) {
      visiblePageNumbers.push(i);
    }
    visiblePageNumbers.push("...");
    visiblePageNumbers.push(totalPages);
  } else if (currentPage >= totalPages - 2) {
    visiblePageNumbers.push(1);
    visiblePageNumbers.push("...");
    for (let i = totalPages - 4; i <= totalPages; i++) {
      visiblePageNumbers.push(i);
    }
  } else {
    visiblePageNumbers.push(1);
    visiblePageNumbers.push("...");
    for (let i = currentPage - 1; i <= currentPage + 1; i++) {
      visiblePageNumbers.push(i);
    }
    visiblePageNumbers.push("...");
    visiblePageNumbers.push(totalPages);
  }
  if (currentPage > 1) {
    visiblePageNumbers.unshift("prev");
  }

  if (currentPage < totalPages) {
    visiblePageNumbers.push("next");
  }

  const paginate = (pageNumber) => {
    if (pageNumber === "prev" && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    } else if (pageNumber === "next" && currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    } else if (pageNumber !== "..." && typeof pageNumber === "number") {
      setCurrentPage(pageNumber);
    }
  };

  const openModal = () => {
    // handleGenerateInvoice(invoiceNo, itemType);
    setcurrentcenter();
    document.getElementById("myModal").style.display = "block";
  };

  const closeModal = () => {
    document.getElementById("myModal").style.display = "none";
    clearFormData();
  };

  const updateModal = (invoice) => {
    // handleGenerateInvoice(invoiceNo, itemType);
    setcurrentcenter(invoice);
    document.getElementById("myupdateModal").style.display = "block";
  };

  const closeupdateModal = () => {
    document.getElementById("myupdateModal").style.display = "none";
    setcurrentcenter("");
    clearFormData();
  };

  // Add center

  const [formData, setFormData] = useState({
    centerName: "",
    address: "",
    zone: "",
  });

  useEffect(() => {
    if (center) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        centerName: center.centerName || "",
        address: center.address || "",
        zone: center.zone || "",
      }));
    }
  }, [center]);

  const clearFormData = () => {
    setFormData({
      centerName: "",
      address: "",
      zone: "",
    });
  };

  // const baseUrl = baseurl.REACT_APP_BASE_URL;

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleBiShowClick = (currentData) => {
    console.log("Selected data for BiShow:", currentData);
  };

  const handleFaRegPenToSquareClick = async () => {
    try {
      const token = sessionStorage.getItem("token");
      const response = await fetch(`${baseUrl}/centers/update/${center.id}`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        const errorResponse = await response.json();
        throw new Error(errorResponse.message);
      }

      const data = await response.json();
      document.getElementById("myModal").style.display = "none";
      alertBox("success", "Successfully Update");

      // Reset form data
      setFormData({
        centerName: "",
        address: "",
        zone: "",
      });
    } catch (error) {
      console.error("Error:", error.message);
      alertBox("error", error.message);
    }
  };

  const handleMdDeleteClick = (currentData) => {
    console.log("Selected data for MdDelete:", currentData);
  };

  const addCenter = async () => {
    let response = null;

    try {
      const token = sessionStorage.getItem("token");
      if (!token) {
        alertBox("error", "No token found. Redirecting to login page.");
        setTimeout(() => {
          window.location.href = "/login";
        }, 2000);
        return;
      }
      response = await fetch(`${baseUrl}/centers/add`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        const errorResponse = await response.json();
        throw new Error(errorResponse.message);
      }

      if (!response.ok) {
        if (response.status === 401) {
          alertBox("error", "Unauthorized access. Redirecting to login page.");
          setTimeout(() => {
            window.location.href = "/login";
          }, 2000); // Wait 2 seconds before redirecting
          return; // Stop further execution
        } else if (response.status >= 500) {
          alertBox(
            "error",
            "Server is currently unavailable. Please try again later."
          );
          // throw new Error("Server is currently unavailable. Please try again later.");
        } else {
          const errorResponse = await response.json();
          throw new Error(errorResponse.message);
        }
      }
      const data = await response.json();
      document.getElementById("myModal").style.display = "none";
      alertBox("success", "Successfully saved");

      // Reset form data
      setFormData({
        centerName: "",
        address: "",
        zone: "",
      });
    } catch (error) {
      if (error.name === 'TypeError' && error.message.includes('Failed to fetch')) {
        alertBox("error", "Network error. Please check your internet connection and try again.");
        setError("Network error. Please check your internet connection and try again.");
      } else {
        alertBox("error", error.message);
        setError(error.message);
      }
    }
  };
  return (
    <div className="new-page-container">
      <div className="common-page-content">
        <CommonPage />
      </div>
      <div className="other-content">
        <div className="master-details-container">
          <div className="left-side">
            <h2>Center Details</h2>
          </div>
          <div className="right-side">
            <div className="buttons">
              <input
                type="text"
                placeholder="Search..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <button>Search</button>
              {/* <button onClick={handleAddClick}>Add</button> */}
              <button className="generate-button" onClick={() => openModal()}>
                Add
              </button>
            </div>
          </div>
        </div>
        <div className="navbar">
          <table className="invoice-table">
            <thead>
              <tr>
                <th>Id</th>
                <th>Address</th>
                <th>Center Name</th>
                <th>Zone</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((invoice, index) => (
                <tr key={index}>
                  <td>{invoice.id}</td>
                  <td>{invoice.address}</td>
                  <td>{invoice.centerName}</td>
                  <td>{invoice.zone}</td>
                  <td>
                    <div className="icon-container">
                      {/* <BiShow
                        className="icon blue"
                        onClick={(e) => handleBiShowClick(invoice)}
                      /> */}
                      <FaRegPenToSquare
                        className="icon green"
                        onClick={(e) => updateModal(invoice)}
                      />
                      {/* <MdDelete
                        className="icon red"
                        onClick={(e) => handleMdDeleteClick(invoice)}
                      /> */}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {invoiceData.length > itemsPerPage && (
          <div className="pagination">
            {visiblePageNumbers.map((number) => (
              <button
                key={number}
                onClick={() => paginate(number)}
                className={
                  typeof number === "number"
                    ? currentPage === number
                      ? "active page-number"
                      : "page-number"
                    : number === "prev" || number === "next"
                    ? "prev-next"
                    : "ellipsis"
                }
              >
                {number}
              </button>
            ))}
          </div>
        )}
      </div>

      <div id="myModal" className="modal">
        <div className="modal-content-center">
          <button className="close-button-center" onClick={closeModal}>
            X
          </button>
          <div className="add-center-form-container">
            <div className="form-heading">
              <span className="form-heading-tax">Add Center</span>
            </div>
            <form className="form-inline">
              <div className="form-group">
                <label htmlFor="centerName">Center Name</label>
                <input
                  type="text"
                  id="centerName"
                  name="centerName"
                  placeholder="Center Name"
                  value={formData.centerName}
                  onChange={handleInputChange}
                  required
                  className="form-input"
                />
              </div>

              <div className="form-group">
                <label htmlFor="address">Address</label>
                <input
                  type="text"
                  id="address"
                  name="address"
                  placeholder="Address"
                  value={formData.address}
                  onChange={handleInputChange}
                  required
                  className="form-input"
                />
              </div>

              <div className="form-group">
                <label htmlFor="zone">Zone</label>
                <input
                  type="text"
                  id="zone"
                  name="zone"
                  placeholder="Zone"
                  value={formData.zone}
                  onChange={handleInputChange}
                  required
                  className="form-input"
                />
              </div>
              <button
                type="button"
                className="submit-button"
                onClick={addCenter}
              >
                Add
              </button>
            </form>
            {/* </div> */}
          </div>
          {/* )} */}
        </div>
      </div>
      <div id="myupdateModal" className="modal">
        <div className="modal-content-center">
          <button className="close-button-center" onClick={closeupdateModal}>
            X
          </button>
          <div className="add-center-form-container">
            <div className="form-heading">
              <span className="form-heading-tax">Update Center</span>
            </div>
            <form className="form-inline">
              <div className="form-group">
                <label htmlFor="centerName">Center Name</label>
                <input
                  type="text"
                  id="centerName"
                  name="centerName"
                  placeholder="Center Name"
                  value={formData.centerName}
                  onChange={handleInputChange}
                  required
                  className="form-input"
                />
              </div>

              <div className="form-group">
                <label htmlFor="address">Address</label>
                <input
                  type="text"
                  id="address"
                  name="address"
                  placeholder={formData.address}
                  value={formData.address}
                  onChange={handleInputChange}
                  required
                  className="form-input"
                />
              </div>

              <div className="form-group">
                <label htmlFor="zone">Zone</label>
                <input
                  type="text"
                  id="zone"
                  name="zone"
                  placeholder="Zone"
                  value={formData.zone}
                  onChange={handleInputChange}
                  required
                  className="form-input"
                />
              </div>

              <button
                type="button"
                className="submit-button"
                onClick={handleFaRegPenToSquareClick}
              >
                Update
              </button>
            </form>
          </div>

          {/* )} */}
        </div>
      </div>
    </div>
  );
}

export default Center;
