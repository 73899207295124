import React, { useState, useEffect } from "react";
import "../invoice/invoicedetails.css";
import baseurl from "../baseurl/baseurl";
import { FaRegPenToSquare } from "react-icons/fa6";
import CommonPage from "../native/commonpage";
import alertBox from "./addcenter";
import Loader from "../baseurl/loader";

function Users() {
  const [invoiceData, setInvoiceData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(8);
  const [searchTerm, setSearchTerm] = useState("");
  const [center, setcurrentcenter] = useState("");
  const baseUrl = baseurl.REACT_APP_BASE_URL;
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    userId: null,
    username: "",
    firstName: "",
    lastName: "",
    email: "",
    contact: null,
    status: true,
    token: null,
    token_creation_date: null,
    centers: [],
    password: "",
    confirmPassword: "",
  });

  const [centers, setCenters] = useState([]);
  const [selectedCenter, setSelectedCenter] = useState("");

  useEffect(() => {
    const fetchCenters = async () => {
      try {
        const token = sessionStorage.getItem("token");
        const response = await fetch(`${baseUrl}/centers`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        if (data.status === "success") {
          setCenters(data.data);
        } else {
          throw new Error(data.message);
        }
      } catch (error) {
        console.error("Error fetching centers:", error);
      }
    };
    fetchCenters();
  }, []);

  const handleAddCenter = () => {
    if (selectedCenter && !formData.centers.includes()) {
      setFormData({
        ...formData,
        centers: [...formData.centers, selectedCenter],
      });
      setSelectedCenter("");
    }
  };

  const handleRemoveCenter = (centerToRemove) => {
    setFormData({
      ...formData,
      centers: formData.centers.filter((center) => center !== centerToRemove),
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    if (center) {
      setFormData({
        ...formData,
        userId: center.userId || null,
        username: center.username || "",
        firstName: center.firstName || "",
        lastName: center.lastName || "",
        email: center.email || "",
        contact: center.contact || null,
        status: center.status || true,
        centers: center.centers || [],
      });
    }
  }, [center]);

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        centers: [...prevFormData.centers, value],
      }));
      setSelectedCenter((prevSelectedCenter) => [...prevSelectedCenter, value]);
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        centers: prevFormData.centers.filter((center) => center !== value),
      }));
      setSelectedCenter((prevSelectedCenter) => prevSelectedCenter.filter((center) => center !== value));
    }
  };

  const [passwordError, setPasswordError] = useState("");
  const clearFormData = () => {
    setFormData({
      userId: null,
      username: "",
      firstName: "",
      lastName: "",
      email: "",
      contact: null,
      status: true,
      token: null,
      token_creation_date: null,
      centers: [],
      password: "",
      confirmPassword: "",
    });
  };

  const paginate = (pageNumber) => {
    if (pageNumber === "prev" && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    } else if (pageNumber === "next" && currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    } else if (pageNumber !== "..." && typeof pageNumber === "number") {
      setCurrentPage(pageNumber);
    }
  };

  // Logic to get current items
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = invoiceData.slice(indexOfFirstItem, indexOfLastItem);
  const startRowNumber =
    currentPage === 1 ? 1 : (currentPage - 1) * itemsPerPage + 1;

  const totalPages = Math.ceil(invoiceData.length / itemsPerPage);

  let visiblePageNumbers = [];

  // Calculate the middle page numbers based on the current page index
  if (totalPages <= 5) {
    // If there are 5 or fewer total pages, add all page numbers
    for (let i = 1; i <= totalPages; i++) {
      visiblePageNumbers.push(i);
    }
  } else if (currentPage <= 3) {
    // If the current page is within the first 3 pages, add the first 5 pages
    for (let i = 1; i <= 5; i++) {
      visiblePageNumbers.push(i);
    }
    // Show ellipsis to indicate more pages
    visiblePageNumbers.push("...");
    visiblePageNumbers.push(totalPages);
  } else if (currentPage >= totalPages - 2) {
    // If the current page is within the last 2 pages, add the last 5 pages
    visiblePageNumbers.push(1);
    visiblePageNumbers.push("...");
    for (let i = totalPages - 4; i <= totalPages; i++) {
      visiblePageNumbers.push(i);
    }
  } else {
    // If the current page is in the middle, show the current page and the previous and next 2 pages
    visiblePageNumbers.push(1);
    visiblePageNumbers.push("...");
    for (let i = currentPage - 1; i <= currentPage + 1; i++) {
      visiblePageNumbers.push(i);
    }
    visiblePageNumbers.push("...");
    visiblePageNumbers.push(totalPages);
  }

  // Add "prev" button if not on the first page
  if (currentPage > 1) {
    visiblePageNumbers.unshift("prev");
  }

  // Add "next" button if not on the last page
  if (currentPage < totalPages) {
    visiblePageNumbers.push("next");
  }

  useEffect(() => {
    fetchData();
  }, [currentPage, itemsPerPage]);

  const fetchData = async () => {
    try {
      const token = sessionStorage.getItem("token");
      const response = await fetch(`${baseUrl}/users`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const data = await response.json();
      if (data.status === "success" && data.data) {
        console.log(data);
        setInvoiceData(data.data);
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  // const handleInputChange = (e) => {
  //   setSearchTerm(e.target.value);
  // };

  const openModal = () => {
    setcurrentcenter("");
    document.getElementById("myModal").style.display = "block";
  };

  const closeModal = () => {
    document.getElementById("myModal").style.display = "none";
    clearFormData();
    setPasswordError("");
  };

  const updateModal = (userData) => {
    setcurrentcenter(userData);
    document.getElementById("myuserupdateModal").style.display = "block";
    setFormData({
      userId: userData.userId || null,
      username: userData.username || "",
      firstName: userData.firstName || "",
      lastName: userData.lastName || "",
      email: userData.email || "",
      contact: userData.contact !== null ? userData.contact : "",
      status: userData.status !== null ? userData.status : true,
      centers: userData.centers || [],  // Ensure centers is correctly populated
      password: "",
      confirmPassword: "",
    });
  };
  
  

  const closeupdateModal = () => {
    document.getElementById("myuserupdateModal").style.display = "none";
    setcurrentcenter("");
    clearFormData();
  };

  // Method to handle update operation
  const handleFaRegPenToSquareClick = async () => {
    try {
      const token = sessionStorage.getItem("token");
      if (!token) {
        alertBox("error", "No token found. Redirecting to login page.");
        setTimeout(() => {
          window.location.href = "/login";
        }, 2000);
        return;
      }
      const response = await fetch(`${baseUrl}/users/${formData.userId}`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        setLoading(false);
        if (response.status === 401) {
          alertBox("error", "Invalid credentials!!");
          setTimeout(() => {
            window.location.href = "/login";
          }, 2000);
          return;
        } else if (response.status >= 500) {
          alertBox(
            "error",
            "Server is currently unavailable. Please try again later."
          );
          throw new Error(
            "Server is currently unavailable. Please try again later."
          );
        } else {
          const errorResponse = await response.json();

          alertBox("error", errorResponse.message);
          throw new Error(errorResponse.message);
        }
      }
      const data = await response.json();
      document.getElementById("myupdateModal").style.display = "none";
      alertBox("success", "Successfully Updated");
      // Reset form data
      clearFormData();
      window.location.reload();
    } catch (error) {
      console.error("Error:", error.message);
      alertBox("error", error.message);
    }
  };

 const addUserData = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${baseUrl}/register`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      console.log(response);
      if (!response.ok) {
        setLoading(false);
        if (response.status === 401) {
          alertBox("error", "Invalid credentials!!");
          setTimeout(() => {
            window.location.href = "/login";
          }, 2000);
          return;
        } else if (response.status >= 500) {
          const errorResponse = await response.json();
          alertBox("error", errorResponse.message);
          throw new Error(errorResponse.message);
        }
      }

      const data = await response.json();
      document.getElementById("myModal").style.display = "none";
      alertBox("success", "Successfully saved");

      // Reset form data
      setFormData({
        id: null,
        username: "",
        firstName: "",
        lastName: "",
        email: "",
        contact: "",
        status: true,
        token: null,
        token_creation_date: null,
        centers: [],
        password: "",
      });
    } catch (error) {
      console.error("Error:", error.message);
      alertBox("error", error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleConfirmPasswordChange = (event) => {
    const { value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      confirmPassword: value,
    }));

    if (value !== formData.password) {
      setPasswordError("Password and confirm password do not match");
    } else {
      setPasswordError("");
    }
  };

  return (
    <div className="new-page-container">
      <div className="common-page-content">
        <CommonPage />
      </div>
      <div className="other-content">
        {loading && (
          <div className="loading">
            <Loader />
          </div>
        )}
        <div className="master-details-container">
          <div className="left-side">
            <h2>Users Details</h2>
          </div>
          <div className="right-side">
            <div className="buttons">
              <input
                type="text"
                placeholder="Search..."
                value={searchTerm}
                onChange={handleInputChange}
              />
              <button className="generate-button" onClick={openModal}>
                Add
              </button>
            </div>
          </div>
        </div>
        <div className="navbar">
          <table className="invoice-table">
            <thead>
              <tr>
                <th>User Id</th>
                <th>User Name</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
                <th>Contact</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((invoice, index) => (
                <tr key={index}>
                  <td>{invoice.userId}</td>
                  <td>{invoice.username}</td>
                  <td>{invoice.firstName}</td>
                  <td>{invoice.lastName}</td>
                  <td>{invoice.email}</td>
                  <td>{invoice.contact}</td>

                  <td>
                    <div className="icon-container">
                      <FaRegPenToSquare
                        className="icon green"
                        onClick={() => updateModal(invoice)}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {invoiceData.length > itemsPerPage && (
          <div className="pagination">
            {visiblePageNumbers.map((number) => (
              <button
                key={number}
                onClick={() => paginate(number)}
                className={
                  currentPage === number ? "active page-number" : "page-number"
                }
              >
                {number}
              </button>
            ))}
          </div>
        )}
      </div>
      <div id="myModal" className="modal">
        <div className="modal-content-center">
          <button className="close-button-center" onClick={closeModal}>
            X
          </button>
          <div className="add-center-form-container">
            <div className="form-heading">
              <span className="form-heading-tax">Add Users</span>
            </div>
            <form className="form-inline">
              <div className="form-row">
                <div className="form-group">
                  <label htmlFor="username">Username</label>
                  <input
                    type="text"
                    id="username"
                    name="username"
                    placeholder="Username"
                    value={formData.username}
                    onChange={handleInputChange}
                    required
                    className="form-input"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="firstName">First Name</label>
                  <input
                    type="text"
                    id="firstName"
                    name="firstName"
                    placeholder="First Name"
                    value={formData.firstName}
                    onChange={handleInputChange}
                    required
                    className="form-input"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="lastName">Last Name</label>
                  <input
                    type="text"
                    id="lastName"
                    name="lastName"
                    placeholder="Last Name"
                    value={formData.lastName}
                    onChange={handleInputChange}
                    required
                    className="form-input"
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Email"
                    value={formData.email}
                    onChange={handleInputChange}
                    required
                    className="form-input"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="contact">Contact</label>
                  <input
                    type="tel"
                    id="contact"
                    name="contact"
                    placeholder="Contact"
                    value={formData.contact}
                    onChange={handleInputChange}
                    required
                    className="form-input"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="password">Password</label>
                  <input
                    type="password"
                    id="password"
                    name="password"
                    placeholder="Password"
                    value={formData.password}
                    onChange={handleInputChange}
                    required
                    className="form-input"
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group">
                  <label htmlFor="confirmPassword">Confirm Password</label>
                  <input
                    type="password"
                    id="confirmPassword"
                    name="confirmPassword"
                    placeholder="Confirm Password"
                    value={formData.confirmPassword}
                    onChange={handleConfirmPasswordChange}
                    required
                    className="form-input"
                  />
                  {passwordError && (
                    <span className="error-message">{passwordError}</span>
                  )}
                </div>

                {/* </div>
        <div className="form-row"> */}

                <div className="form-group">
                  <label htmlFor="status">Status</label>
                  <select
                    id="status"
                    name="status"
                    value={formData.status}
                    onChange={handleInputChange}
                    required
                    className="form-input"
                  >
                    <option value={true}>Active</option>
                    <option value={false}>Inactive</option>
                  </select>
                </div>

                <div className="form-group">
                  <label>Centers</label>
                  <div className="centers-container">
                    {centers.map((center) => (
                      <div
                        key={center.id}
                        className={`center-checkbox ${
                          formData.centers.includes(center.centerName)
                            ? "checked"
                            : ""
                        }`}
                      >
                        <input
                          type="checkbox"
                          id={`center-${center.id}`}
                          value={center.centerName}
                          checked={formData.centers.includes(center.centerName)}
                          onChange={handleCheckboxChange}
                          className="form-checkbox"
                        />
                        <label htmlFor={`center-${center.id}`}>
                          {center.centerName}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="form-row">
                <button
                  type="button"
                  className="submit-button"
                  onClick={addUserData}
                >
                  Add
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div id="myuserupdateModal" className="modal">
        <div className="modal-content-center">
          <button className="close-button-center" onClick={closeupdateModal}>
            X
          </button>
          <div className="add-center-form-container">
            <div className="form-heading">
              <span className="form-heading-tax">Update Users</span>
            </div>
            <form className="form-inline">
              <div className="form-row">
                <div className="form-group">
                  <label htmlFor="username">Username</label>
                  <input
                    type="text"
                    id="username"
                    name="username"
                    placeholder="Username"
                    value={formData.username || ""}
                    onChange={handleInputChange}
                    required
                    className="form-input"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="firstName">First Name</label>
                  <input
                    type="text"
                    id="firstName"
                    name="firstName"
                    placeholder="First Name"
                    value={formData.firstName || ""}
                    onChange={handleInputChange}
                    required
                    className="form-input"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="lastName">Last Name</label>
                  <input
                    type="text"
                    id="lastName"
                    name="lastName"
                    placeholder="Last Name"
                    value={formData.lastName || ""}
                    onChange={handleInputChange}
                    required
                    className="form-input"
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Email"
                    value={formData.email || ""}
                    onChange={handleInputChange}
                    required
                    className="form-input"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="contact">Contact</label>
                  <input
                    type="tel"
                    id="contact"
                    name="contact"
                    placeholder="Contact"
                    value={formData.contact || ""}
                    onChange={handleInputChange}
                    required
                    className="form-input"
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="status">Status</label>
                  <select
                    id="status"
                    name="status"
                    value={formData.status}
                    onChange={handleInputChange}
                    required
                    className="form-input"
                  >
                    <option value={true}>Active</option>
                    <option value={false}>Inactive</option>
                  </select>
                </div>
                <div className="form-group">
  <label>Centers</label>
  <div className="centers-container">
    {centers.map((center) => (
      <div
        key={center.centerName}
        className={`center-checkbox ${
          formData.centers.includes(center.centerName) ? "checked" : ""
        }`}
      >
        <input
          type="checkbox"
          id={`center-${center.centerName}`}
          value={center.centerName}
          checked={formData.centers.includes(center.centerName)}
          onChange={handleCheckboxChange}
          className="form-checkbox"
        />
        <label htmlFor={`center-${center.centerName}`}>
          {center.centerName}
        </label>
      </div>
    ))}
  </div>
</div>


              </div>
              <div className="form-row">
                <button
                  type="button"
                  className="submit-button"
                  onClick={handleFaRegPenToSquareClick}
                >
                  Update
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Users;
