import React, { useState } from "react";


function alertBox (type, message)  {
    const alertDiv = document.createElement("div");
    alertDiv.classList.add("alert");
  
    if (type === "success") {
      alertDiv.style.backgroundColor = "green";
    } else if (type === "error") {
      alertDiv.style.backgroundColor = "red";
    }
  
    alertDiv.innerText = message;
    alertDiv.style.position = "fixed";
    alertDiv.style.top = "10px";
    alertDiv.style.right = "10px";
    alertDiv.style.width = "200px";
    alertDiv.style.textAlign = "center";
    alertDiv.style.padding = "5px";
    alertDiv.style.borderRadius = "5px";
  
    document.body.appendChild(alertDiv);
  
    // Remove the alert after 5 seconds
    setTimeout(() => {
      alertDiv.remove();
    }, 2000);
  };



export default alertBox;
