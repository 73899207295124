import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./newpage.css";
import CommonPage from "../native/commonpage";
import baseurl from "../baseurl/baseurl";
import alertBox from "./addcenter";
import Loader from "../baseurl/loader";

function NewDashboard() {
  const [dashboardData, setDashboardData] = useState({});
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [centerNames, setCenterNames] = useState([]);
  const baseUrl = baseurl.REACT_APP_BASE_URL;
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/dashboard") {
      fetchDataByType();
    }
  }, [location.pathname]);

  const fetchDataByType = async () => {
    setLoading(true);
    try {
      const token = sessionStorage.getItem("token");
      if (!token) {
        alertBox("error", "No token found. Redirecting to login page.");
        setTimeout(() => {
          window.location.href = "/login";
        }, 2000);
        return;
      }

      const centers = sessionStorage.getItem("centers");
      if (centers) {
        const centersArray = Array.from(new Set(centers.split("%2C").map(decodeURIComponent)));
        setCenterNames(centersArray);
      }

      const centersQueryParam = centers.split("%2C")
        .map((center) => `centers=${encodeURIComponent(center)}`)
        .join("&");

      const queryParams = `?${centersQueryParam}`;

      const response = await fetch(
        `${baseUrl}/receipts/dashboard${queryParams}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        if (response.status === 401) {
          alertBox("error", "Unauthorized access. Redirecting to login page.");
          setTimeout(() => {
            window.location.href = "/login";
          }, 2000);
          return;
        } else if (response.status >= 500) {
          alertBox("error", "Server is currently unavailable. Please try again later.");
        } else {
          throw new Error("Failed to fetch data. Please check your network connection and try again.");
        }
      }

      const data = await response.json();
      if (data.status === "success") {
        alertBox("success", "Data fetched successfully");
        setDashboardData(data.data);
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      if (error.name === "TypeError" && error.message.includes("Failed to fetch")) {
        alertBox("error", "Network error. Please check your internet connection and try again.");
        setError("Network error. Please check your internet connection and try again.");
      } else {
        alertBox("error", error.message);
        setError(error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleRedirect = (path) => {
    navigate(path);
  };

  return (
    <div className="new-page-container">
      <CommonPage />
      <div className="other-content">
      {/* {centerNames.map((center, index) => (
              <h2 key={index}>{center}</h2>
            ))} */}
        <div className="content">
         
          <div className="data-tabs">
            <div className="dashboard-row">
              <div
                className="dashboard-box dashboard-color1"
              >
                <h2>
                  <i className="fa-solid fa-coins"></i>
                </h2>
                {loading && (
                  <div className="loading">
                    <Loader />
                  </div>
                )}
                <h4>Total Invoices</h4>
                <h3>{dashboardData.total && dashboardData.total.totalSales}</h3>
              </div>
              <div
                className="dashboard-box dashboard-color2" style={{ pointerEvents: 'none' }}
              >
                <h2>
                  <i className="fa-solid fa-rotate"></i>
                </h2>
                <h4>Product Invoices</h4>
                <h3>
                  {dashboardData.product && dashboardData.product.totalSales}
                </h3>
              </div>
              <div
                className="dashboard-box dashboard-color4"
               
              >
                <h2>
                  <i className="fa-regular fa-circle-check"></i>
                </h2>
                <h4>Service Invoices</h4>
                <h3>
                  {dashboardData.service && dashboardData.service.totalSales}
                </h3>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewDashboard;
