import React, { useState, useEffect } from "react";
import "../invoice/invoicedetails.css";
import baseurl from "../baseurl/baseurl";
import CommonPage from "../native/commonpage";

function Profile() {
  const [center, setcurrentcenter] = useState("");
  const [invoiceData, setInvoiceData] = useState([]);
  const [centerData, setCenterData] = useState([]);

  
  const baseUrl = baseurl.REACT_APP_BASE_URL;

  const [formData, setFormData] = useState({
    id: null,
    username: "",
    firstName: "",
    lastName: "",
    email: "",
    contact: null,
    status: true,
    token: null,
    token_creation_date: null,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const fetchCenterData = async () => {
    try {
      const token = sessionStorage.getItem("token");
      const response = await fetch(`${baseUrl}/centers`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const data = await response.json();
      if (data.status === "success") {
        setCenterData(data.data);
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  useEffect(() => {
    if (center) {
      setFormData({
        ...formData,
        id: center.userId || null,
        username: center.username || "",
        firstName: center.firstName || "",
        lastName: center.lastName || "",
        email: center.email || "",
        contact: center.contact || null,
        status: center.status || true,
      });
    }
  }, [center]);

  const clearFormData = () => {
    setFormData({
      id: null,
      username: "",
      firstName: "",
      lastName: "",
      email: "",
      contact: null,
      status: true,
      token: null,
      token_creation_date: null,
    });
  };

  useEffect(() => {
    fetchCenterData();
    fetchData();
  }, []); // Empty dependency array to run only on mount

  const fetchData = async () => {
    try {
      const token = sessionStorage.getItem("token");
      const username = sessionStorage.getItem("username");

      const response = await fetch(`${baseUrl}/userinfo/${username}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const data = await response.json();

      if (data.status === "success" && data.data) {
        console.log(data.data);

        setInvoiceData(data.data);
        setcurrentcenter(data.data);

        const center = data.data;

        setFormData({
          id: center.userId || null,
          username: center.username || "",
          firstName: center.firstName || "",
          lastName: center.lastName || "",
          email: center.email || "",
          contact: center.contact || null,
          status: center.status || true,
        });
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  const AddUser = async () => {
    try {
      const token = sessionStorage.getItem("token");
      const response = await fetch(`${baseUrl}`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        const errorResponse = await response.json();
        throw new Error(errorResponse.message);
      }

      const data = await response.json();
      document.getElementById("myupdateModal").style.display = "none";
      alert("Successfully Updated");

      clearFormData();
      window.location.reload();
    } catch (error) {
      console.error("Error:", error.message);
      alert("Error:", error.message);
    }
  };

  const UpdateProfile = async () => {
    try {
      const token = sessionStorage.getItem("token");
      const response = await fetch(`${baseUrl}/services/update/${center.id}`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        const errorResponse = await response.json();
        throw new Error(errorResponse.message);
      }

      const data = await response.json();
      document.getElementById("myupdateModal").style.display = "none";
      alert("Successfully Updated");

      clearFormData();
      window.location.reload();
    } catch (error) {
      console.error("Error:", error.message);
      alert("Error:", error.message);
    }
  };

  return (
    <div className="new-page-container">
      <div className="common-page-content">
        <CommonPage />
      </div>
      <div className="other-content">
        <div className="master-details-container">
          <div className="left-side">
            <h2>My Details</h2>
          </div>
        </div>
        <div className="navbar">
          <div className="right-side">
            <div className="add-center-form-container">
              <div className="form-heading">
                <span className="form-heading-tax">Update Users</span>
              </div>
              <form className="form-inline">
                <div className="form-group">
                  <label htmlFor="username">Username</label>
                  <input
                    type="text"
                    id="username"
                    name="username"
                    placeholder="Username"
                    value={formData.username}
                    onChange={handleInputChange}
                    required
                    className="form-input"
                    style={{ width: '200px', padding: '5px', borderRadius: '3px' }} // Custom styles
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="firstName">First Name</label>
                  <input
                    type="text"
                    id="firstName"
                    name="firstName"
                    placeholder="First Name"
                    value={formData.firstName}
                    onChange={handleInputChange}
                    required
                    className="form-input"
                    style={{ width: '200px', padding: '5px', borderRadius: '3px' }} // Custom styles
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="lastName">Last Name</label>
                  <input
                    type="text"
                    id="lastName"
                    name="lastName"
                    placeholder="Last Name"
                    value={formData.lastName}
                    onChange={handleInputChange}
                    required
                    className="form-input"
                    style={{ width: '200px', padding: '5px', borderRadius: '3px' }} // Custom styles
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Email"
                    value={formData.email}
                    onChange={handleInputChange}
                    required
                    className="form-input"
                    style={{ width: '200px', padding: '5px', borderRadius: '3px' }} // Custom styles
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="contact">Contact</label>
                  <input
                    type="tel"
                    id="contact"
                    name="contact"
                    placeholder="Contact"
                    value={formData.contact}
                    onChange={handleInputChange}
                    required
                    className="form-input"
                    style={{ width: '200px', padding: '5px', borderRadius: '3px' }} // Custom styles
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="status">Status</label>
                  <select
                    id="status"
                    name="status"
                    value={formData.status}
                    onChange={handleInputChange}
                    required
                    className="form-input"
                    style={{ width: '200px', padding: '5px', borderRadius: '3px' }} // Custom styles
                  >
                    <option value={true}>Active</option>
                    <option value={false}>Inactive</option>
                  </select>
                </div>
              </form>
              <button
                type="button"
                className="submit-button"
                onClick={UpdateProfile}
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;
