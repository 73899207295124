import "./App.css";
import Login from "./component/login/login";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"; // Import BrowserRouter, Routes, and Route
import ForgotPassword from "./component/forgotpassword/forgotpassword";
import Invoicedetails from "./component/invoice/invoicedetails";
import Invoice from "./component/invoice/invoice";
import Navigation from "./component/native/navigation";
import CommonPage from "./component/native/commonpage";
import NewPage from "./component/dashboard/newpage";
import Center from "./component/dashboard/center";
import NewDashboard from "./component/dashboard/newdashboard";
import LineGraph from "./component/dashboard/graph";
import ProductChart from "./component/dashboard/productchart";
import AddCenterForm from "./component/dashboard/addcenter";
import Product from "./component/dashboard/product";
import Service from "./component/dashboard/service";
import Users from "./component/dashboard/users";
import Profile from "./component/dashboard/profile";
import CancelInvoicedetails from "./component/invoice/cancel";
import Resetpassword from "./component/forgotpassword/reset-password";
import ReportProduct from "./component/dashboard/report-product";
import Reportservice from "./component/dashboard/report-service";



function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />{" "}
        {/* Use "path" instead of "exact path" */}
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<Resetpassword />} />
        <Route path="/invoice" element={<Invoice />} />
        <Route path="/dashboard" element={<NewDashboard />} />
        <Route path="/graph" element={<LineGraph />} />
        <Route path="/product-graph" element={<ProductChart />} />
        <Route path="/master-product-details" element={<Product />} />
        <Route path="/master-service-details" element={<Service />} />
        <Route path="/master-users-details" element={<Users />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/master-center-details" element={<Center />} />
        <Route path="/add-center" element={<AddCenterForm />} />
        <Route path="/navigation" element={<Navigation />} />
        <Route path="/commonpage" element={<CommonPage />} />
        <Route path="/new-page" element={<NewPage />} />
        <Route path="/invoice-details" element={<Invoicedetails />} />
        <Route path="/cancel-invoice-details" element={<CancelInvoicedetails />} />
        <Route path="/productreport" element={<ReportProduct />} />
        <Route path="/servicereport" element={<Reportservice />} />
      </Routes>
    </Router>
  );
}

export default App;
