import React, { useState, useEffect } from "react";
import "../invoice/invoicedetails.css";
import baseurl from "../baseurl/baseurl";
import { FaRegPenToSquare } from "react-icons/fa6";
import CommonPage from "../native/commonpage";

function Product() {
  const [invoiceData, setInvoiceData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(8);
  const [searchTerm, setSearchTerm] = useState("");
  const [center, setcurrentcenter] = useState("");
  const baseUrl = baseurl.REACT_APP_BASE_URL;

  const paginate = (pageNumber) => {
    if (pageNumber === "prev" && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    } else if (pageNumber === "next" && currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    } else if (pageNumber !== "..." && typeof pageNumber === "number") {
      setCurrentPage(pageNumber);
    }
  };

  // Logic to get current items
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = invoiceData.slice(indexOfFirstItem, indexOfLastItem);
  const startRowNumber =
    currentPage === 1 ? 1 : (currentPage - 1) * itemsPerPage + 1;

  const totalPages = Math.ceil(invoiceData.length / itemsPerPage);

  let visiblePageNumbers = [];

  // Calculate the middle page numbers based on the current page index
  if (totalPages <= 5) {
    // If there are 5 or fewer total pages, add all page numbers
    for (let i = 1; i <= totalPages; i++) {
      visiblePageNumbers.push(i);
    }
  } else if (currentPage <= 3) {
    // If the current page is within the first 3 pages, add the first 5 pages
    for (let i = 1; i <= 5; i++) {
      visiblePageNumbers.push(i);
    }
    // Show ellipsis to indicate more pages
    visiblePageNumbers.push("...");
    visiblePageNumbers.push(totalPages);
  } else if (currentPage >= totalPages - 2) {
    // If the current page is within the last 2 pages, add the last 5 pages
    visiblePageNumbers.push(1);
    visiblePageNumbers.push("...");
    for (let i = totalPages - 4; i <= totalPages; i++) {
      visiblePageNumbers.push(i);
    }
  } else {
    // If the current page is in the middle, show the current page and the previous and next 2 pages
    visiblePageNumbers.push(1);
    visiblePageNumbers.push("...");
    for (let i = currentPage - 1; i <= currentPage + 1; i++) {
      visiblePageNumbers.push(i);
    }
    visiblePageNumbers.push("...");
    visiblePageNumbers.push(totalPages);
  }

  // Add "prev" button if not on the first page
  if (currentPage > 1) {
    visiblePageNumbers.unshift("prev");
  }

  // Add "next" button if not on the last page
  if (currentPage < totalPages) {
    visiblePageNumbers.push("next");
  }

  useEffect(() => {
    fetchData();
  }, [currentPage, itemsPerPage]);

  const fetchData = async () => {
    try {
      const token = sessionStorage.getItem("token");
      const response = await fetch(`${baseUrl}/products`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const data = await response.json();
      if (data.status === "success" && data.data) {
        console.log(data.data);
        setInvoiceData(data.data);
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const openModal = () => {
    setcurrentcenter("");
    document.getElementById("myModal").style.display = "block";
  };

  const closeModal = () => {
    document.getElementById("myModal").style.display = "none";
    clearFormData();
  };

  const updateModal = (invoice) => {
    setcurrentcenter(invoice);
    document.getElementById("myupdateModal").style.display = "block";
  };

  const closeupdateModal = () => {
    document.getElementById("myupdateModal").style.display = "none";
    setcurrentcenter("");
    clearFormData();
  };

  // Add center
  const [formData, setFormData] = useState({
    id: null,
    productCode: "",
    productName: "",
    category: "",
    subCategory: "",
    businessUnitName: "",
    productType: "",
    inUse: "",
    taxInPercentage: null,
    name: "",
  });

  useEffect(() => {
    if (center) {
      setFormData({
        id: center.id || null,
        productCode: center.productCode || "",
        productName: center.productName || "",
        category: center.category || "",
        subCategory: center.subCategory || "",
        businessUnitName: center.businessUnitName || "",
        productType: center.productType || "",
        inUse: center.inUse || "",
        taxInPercentage: center.taxInPercentage || null,
        name: center.name || "",
      });
    }
  }, [center]);

  const clearFormData = () => {
    setFormData({
      id: null,
      productCode: "",
      productName: "",
      category: "",
      subCategory: "",
      businessUnitName: "",
      productType: "",
      inUse: "",
      taxInPercentage: null,
      name: "",
    });
  };

  const handleFaRegPenToSquareClick = async () => {
    try {
      const token = sessionStorage.getItem("token");
      const response = await fetch(`${baseUrl}/centers/update/${center.id}`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        const errorResponse = await response.json();
        throw new Error(errorResponse.message);
      }

      const data = await response.json();
      document.getElementById("myModal").style.display = "none";
      alert("Successfully Update");

      // Reset form data
      clearFormData();
    } catch (error) {
      console.error("Error:", error.message);
      alert("Error:", error.message);
    }
  };

  const addProduct = async () => {
    try {
      const token = sessionStorage.getItem("token");
      const response = await fetch(`${baseUrl}/centers/add`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        const errorResponse = await response.json();
        throw new Error(errorResponse.message);
      }

      const data = await response.json();
      document.getElementById("myModal").style.display = "none";
      alert("Successfully saved");

      // Reset form data
      clearFormData();
    } catch (error) {
      console.error("Error:", error.message);
      alert("Error:", error.message);
    }
  };

  //   const currentItems = invoiceData
  //     .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  //   const totalPages = Math.ceil(invoiceData.length / itemsPerPage);

  //   const visiblePageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);

  //   const paginate = (pageNumber) => {
  //     setCurrentPage(pageNumber);
  //   };

  return (
    <div className="new-page-container">
      <div className="common-page-content">
        <CommonPage />
      </div>
      <div className="other-content">
        <div className="master-details-container">
          <div className="left-side">
            <h2>Product Details</h2>
          </div>
          <div className="right-side">
            <div className="buttons">
              <input
                type="text"
                placeholder="Search..."
                value={searchTerm}
                onChange={handleInputChange}
              />
              <button className="generate-button" onClick={openModal}>
                Add
              </button>
            </div>
          </div>
        </div>
        <div className="navbar">
          <table className="invoice-table">
            <thead>
              <tr>
                <th>Product Id</th>
                <th>Business Unit Name</th>
                <th>Category</th>
                <th>In Use</th>
                <th>Product Code</th>
                <th>Product Name</th>
                <th>Product Type</th>
                <th>Sub Category</th>
                <th>Tax(%)</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((invoice, index) => (
                <tr key={index}>
                  <td>{invoice.id}</td>
                  <td>{invoice.businessUnitName}</td>
                  <td>{invoice.category}</td>
                  <td>{invoice.inUse}</td>
                  <td>{invoice.productCode}</td>
                  <td>{invoice.productName}</td>
                  <td>{invoice.productType}</td>
                  <td>{invoice.subCategory}</td>
                  <td>{invoice.taxInPercentage}</td>
                  <td>
                    <div className="icon-container">
                      <FaRegPenToSquare
                        className="icon green"
                        onClick={() => updateModal(invoice)}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {invoiceData.length > itemsPerPage && (
          <div className="pagination">
            {visiblePageNumbers.map((number) => (
              <button
                key={number}
                onClick={() => paginate(number)}
                className={
                  currentPage === number ? "active page-number" : "page-number"
                }
              >
                {number}
              </button>
            ))}
          </div>
        )}
      </div>

      <div id="myModal" className="modal">
        <div className="modal-content-center">
          <button className="close-button-center" onClick={closeModal}>
            X
          </button>
          <div className="add-center-form-container">
            <div className="form-heading">
              <span className="form-heading-tax">Add Product</span>
            </div>
            <form className="form-inline">
              <div className="form-group">
                <label htmlFor="centerName">Product Name</label>
                <input
                  type="text"
                  id="centerName"
                  name="centerName"
                  placeholder="Center Name"
                  value={formData.centerName}
                  onChange={handleInputChange}
                  required
                  className="form-input"
                />
              </div>

              <div className="form-group">
                <label htmlFor="address">Address</label>
                <input
                  type="text"
                  id="address"
                  name="address"
                  placeholder="Address"
                  value={formData.address}
                  onChange={handleInputChange}
                  required
                  className="form-input"
                />
              </div>

              <div className="form-group">
                <label htmlFor="zone">Zone</label>
                <input
                  type="text"
                  id="zone"
                  name="zone"
                  placeholder="Zone"
                  value={formData.zone}
                  onChange={handleInputChange}
                  required
                  className="form-input"
                />
              </div>
              <button
                type="button"
                className="submit-button"
                onClick={addProduct}
              >
                Add
              </button>
            </form>
          </div>
        </div>
      </div>
      <div id="myupdateModal" className="modal">
  <div className="modal-content-center">
    <button className="close-button-center" onClick={closeupdateModal}>
      X
    </button>
    <div className="add-center-form-container">
      <div className="form-heading">
        <span className="form-heading-tax">Update Product</span>
      </div>
      <form className="form-inline">
        <div className="form-group">
          <label htmlFor="id">ID</label>
          <input
            type="text"
            id="id"
            name="id"
            placeholder="ID"
            value={formData.id}
            onChange={handleInputChange}
            required
            className="form-input"
          />
        </div>

        <div className="form-group">
          <label htmlFor="productCode">Product Code</label>
          <input
            type="text"
            id="productCode"
            name="productCode"
            placeholder="Product Code"
            value={formData.productCode}
            onChange={handleInputChange}
            required
            className="form-input"
          />
        </div>

        <div className="form-group">
          <label htmlFor="productName">Product Name</label>
          <input
            type="text"
            id="productName"
            name="productName"
            placeholder="Product Name"
            value={formData.productName}
            onChange={handleInputChange}
            required
            className="form-input"
          />
        </div>

        <div className="form-group">
          <label htmlFor="category">Category</label>
          <input
            type="text"
            id="category"
            name="category"
            placeholder="Category"
            value={formData.category}
            onChange={handleInputChange}
            required
            className="form-input"
          />
        </div>

        <div className="form-group">
          <label htmlFor="subCategory">Sub-Category</label>
          <input
            type="text"
            id="subCategory"
            name="subCategory"
            placeholder="Sub-Category"
            value={formData.subCategory}
            onChange={handleInputChange}
            required
            className="form-input"
          />
        </div>

        <div className="form-group">
          <label htmlFor="businessUnitName">Business Unit Name</label>
          <input
            type="text"
            id="businessUnitName"
            name="businessUnitName"
            placeholder="Business Unit Name"
            value={formData.businessUnitName}
            onChange={handleInputChange}
            required
            className="form-input"
          />
        </div>

        <div className="form-group">
          <label htmlFor="productType">Product Type</label>
          <input
            type="text"
            id="productType"
            name="productType"
            placeholder="Product Type"
            value={formData.productType}
            onChange={handleInputChange}
            required
            className="form-input"
          />
        </div>

        <div className="form-group">
          <label htmlFor="inUse">In Use</label>
          <input
            type="text"
            id="inUse"
            name="inUse"
            placeholder="In Use"
            value={formData.inUse}
            onChange={handleInputChange}
            required
            className="form-input"
          />
        </div>

        <div className="form-group">
          <label htmlFor="taxInPercentage">Tax In Percentage</label>
          <input
            type="text"
            id="taxInPercentage"
            name="taxInPercentage"
            placeholder="Tax In Percentage"
            value={formData.taxInPercentage}
            onChange={handleInputChange}
            required
            className="form-input"
          />
        </div>

        <button
          type="button"
          className="submit-button"
          onClick={handleFaRegPenToSquareClick}
        >
          Update
        </button>
      </form>
    </div>
  </div>
</div>

    </div>
  );
}

export default Product;
