import React, { useState, useEffect } from "react";
import { FaRegCircleUser, FaFileInvoiceDollar } from "react-icons/fa6"; // Changed to fa6 for FaRegCircleUser
import { IoHomeOutline } from "react-icons/io5";
import { FaNetworkWired, FaProductHunt } from "react-icons/fa";
import { FaServicestack } from "react-icons/fa";
import { MdModeStandby } from "react-icons/md";
import { FaUser } from "react-icons/fa";
import { MdReport } from "react-icons/md";
import { useNavigate, useLocation } from "react-router-dom";
import { VscError } from "react-icons/vsc";
import logo from "../../images/logo2.png";
import logout from "../../images/logout.png";

import "./commonpage.css";
import alertBox from "../dashboard/addcenter";

function CommonPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedManu, setSelectedManu] = useState("");
  const [selectedSubmanu, setSelectedSubmanu] = useState("");
  const [isMasterSubMenuVisible, setIsMasterSubMenuVisible] = useState(false);
  const [isInvoiceSubMenuVisible, setIsInvoiceSubMenuVisible] = useState(false);
  const [isProfileSubMenuVisible, setIsProfileSubMenuVisible] = useState(false);
  const [isReportSubMenuVisible, setIsReportSubMenuVisible] = useState(false);
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);

  useEffect(() => {
    const pathname = location.pathname;
    if (pathname === "/dashboard") {
      setSelectedManu("dashboard");
      setSelectedSubmanu("");
    } else if (pathname === "/master-details") {
      setSelectedManu("master");
      setSelectedSubmanu("");
      setIsMasterSubMenuVisible(true);
      setIsInvoiceSubMenuVisible(false);
      setIsProfileSubMenuVisible(false);
      setIsReportSubMenuVisible(false);
    } else if (pathname.includes("/center-details")) {
      setSelectedManu("master");
      setSelectedSubmanu("center");
      setIsMasterSubMenuVisible(true);
      setIsInvoiceSubMenuVisible(false);
      setIsProfileSubMenuVisible(false);
      setIsReportSubMenuVisible(false);
    } else if (pathname.includes("/product-details")) {
      setSelectedManu("master");
      setSelectedSubmanu("product");
      setIsMasterSubMenuVisible(true);
      setIsInvoiceSubMenuVisible(false);
      setIsProfileSubMenuVisible(false);
      setIsReportSubMenuVisible(false);
    } else if (pathname === "/invoice-details") {
      setSelectedManu("invoice");
      setSelectedSubmanu(location.state.type.replace(" ", "-"));
      setIsMasterSubMenuVisible(false);
      setIsInvoiceSubMenuVisible(true);
      setIsProfileSubMenuVisible(false);
      setIsReportSubMenuVisible(false);
    } else if (pathname === "/my-profile") {
      setSelectedManu("myprofile");
      setSelectedSubmanu("");
      setIsMasterSubMenuVisible(false);
      setIsInvoiceSubMenuVisible(false);
      setIsProfileSubMenuVisible(true);
      setIsReportSubMenuVisible(false);
    } else if (pathname === "/productreport") {
      setSelectedManu("report");
      setSelectedSubmanu("product");
      setIsMasterSubMenuVisible(false);
      setIsInvoiceSubMenuVisible(false);
      setIsProfileSubMenuVisible(false);
      setIsReportSubMenuVisible(true);
    } else if (pathname === "/servicereport") {
      setSelectedManu("report");
      setSelectedSubmanu("service");
      setIsMasterSubMenuVisible(false);
      setIsInvoiceSubMenuVisible(false);
      setIsProfileSubMenuVisible(false);
      setIsReportSubMenuVisible(true);
    }
  }, [location.pathname]);

  const handleMenuItemClick = (manu) => {
    setSelectedManu(manu);
    setSelectedSubmanu("");
    if (manu === "dashboard") {
      navigate("/dashboard");
    } else if (manu === "master") {
      setIsMasterSubMenuVisible(!isMasterSubMenuVisible);
      setIsInvoiceSubMenuVisible(false);
      setIsProfileSubMenuVisible(false);
      setIsReportSubMenuVisible(false);
    } else if (manu === "invoice") {
      setIsInvoiceSubMenuVisible(!isInvoiceSubMenuVisible);
      setIsMasterSubMenuVisible(false);
      setIsProfileSubMenuVisible(false);
      setIsReportSubMenuVisible(false);
    } else if (manu === "myprofile") {
      setIsProfileSubMenuVisible(!isProfileSubMenuVisible);
      setIsMasterSubMenuVisible(false);
      setIsInvoiceSubMenuVisible(false);
      setIsReportSubMenuVisible(false);
    } else if (manu === "report") {
      setIsReportSubMenuVisible(!isReportSubMenuVisible);
      setIsMasterSubMenuVisible(false);
      setIsInvoiceSubMenuVisible(false);
      setIsProfileSubMenuVisible(false);
    }
  };

  const handleSubMenuClick = (manu, submanu) => {
    setSelectedManu(manu);
    setSelectedSubmanu(submanu);
    switch (submanu) {
      case "product":
      case "center":
      case "service":
      case "users":
        setSelectedManu("master");
        navigate(`/master-${submanu}-details`);
        break;
      case "cancel-invoice":
        setSelectedManu("invoice");
        navigate("/cancel-invoice-details", { state: { type: "Cancel" } });
        break;
      case "all-invoice":
        setSelectedManu("invoice");
        navigate("/invoice-details", { state: { type: "All" } });
        break;
      case "profile-update":
        setSelectedManu("myprofile");
        navigate("/profile");
        break;
      case "logout":
        openlogoutModal();
        break;
      case "report-product":
        setSelectedManu("report");
        navigate("/productreport");
        break;
      case "report-service":
        setSelectedManu("report");
        navigate("/servicereport");
        break;
      default:
        break;
    }
  };

  const openlogoutModal = () => {
    console.log("open closed");
    document.getElementById("logoutModal").style.display = "block";
  };

  const handleLogout = () => {
    sessionStorage.removeItem("token");
    alertBox("success", "Logout!!");
    window.location.href = "/";
  };

  const closeLogoutModal = () => {
    document.getElementById("logoutModal").style.display = "none";
  };

  return (
    <div className="sidebar">
     <div className="header-logos">
        <a href="#">
          <img
            src={logo}
            width="140"
            height="49"
            alt="Logo"

            style={{ filter: "brightness(0) invert(1)" }}
          />
        </a>
      </div>


      <div
        className={`menu-item ${
          selectedManu === "dashboard" ? "selected-menu-item" : ""
        }`}
        onClick={() => handleMenuItemClick("dashboard")}
      >
        <IoHomeOutline className="menu-icon" />
        Dashboard
      </div>
      <div
        className={`menu-item ${
          selectedManu === "master" ? "selected-menu-item" : ""
        }`}
        onClick={() => handleMenuItemClick("master")}
      >
        <FaRegCircleUser className="menu-icon" />
        Master
      </div>
      {isMasterSubMenuVisible && (
        <div className="submenu">
          <div
            className={`submenu-item ${
              selectedSubmanu === "product" ? "selected-submenu-item" : ""
            }`}
            onClick={() => handleSubMenuClick("master", "product")}
          >
            <FaProductHunt className="menu-icon" />
            Product
          </div>
          <div
            className={`submenu-item ${
              selectedSubmanu === "center" ? "selected-submenu-item" : ""
            }`}
            onClick={() => handleSubMenuClick("master", "service")}
          >
            <FaServicestack className="menu-icon" />
            Service
          </div>
          <div
            className={`submenu-item ${
              selectedSubmanu === "center" ? "selected-submenu-item" : ""
            }`}
            onClick={() => handleSubMenuClick("master", "users")}
          >
            <FaRegCircleUser className="menu-icon" />
            Users
          </div>
          <div
            className={`submenu-item ${
              selectedSubmanu === "center" ? "selected-submenu-item" : ""
            }`}
            onClick={() => handleSubMenuClick("master", "center")}
          >
            <MdModeStandby className="menu-icon" />
            Center
          </div>
        </div>
      )}
      <div
        className={`menu-item ${
          selectedManu === "invoice" ? "selected-menu-item" : ""
        }`}
        onClick={() => handleMenuItemClick("invoice")}
      >
        <FaFileInvoiceDollar className="menu-icon" />
        Invoice
      </div>
      {isInvoiceSubMenuVisible && (
        <div className="submenu">
          <div
            className={`submenu-item ${
              selectedSubmanu === "all-invoice" ? "selected-submenu-item" : ""
            }`}
            onClick={() => handleSubMenuClick("invoice", "all-invoice")}
          >
            <FaFileInvoiceDollar className="menu-icon" />
            Invoice
          </div>
          <div
            className={`submenu-item ${
              selectedSubmanu === "service-invoice"
                ? "selected-submenu-item"
                : ""
            }`}
            onClick={() => handleSubMenuClick("invoice", "cancel-invoice")}
          >
            <FaNetworkWired className="menu-icon" />
            Cancel Invoice
          </div>
        </div>
      )}
      <div
        className={`menu-item ${
          selectedManu === "report" ? "selected-menu-item" : ""
        }`}
        onClick={() => handleMenuItemClick("report")}
      >
        <MdReport className="menu-icon" />
        Report
      </div>
      {isReportSubMenuVisible && (
        <div className="submenu">
          <div
            className={`submenu-item ${
              selectedSubmanu === "report-product" ? "selected-submenu-item" : ""
            }`}
            onClick={() => handleSubMenuClick("report", "report-product")}
          >
            <FaProductHunt className="menu-icon" />
            Product
          </div>
          <div
            className={`submenu-item ${
              selectedSubmanu === "report-service" ? "selected-submenu-item" : ""
            }`}
            onClick={() => handleSubMenuClick("report", "report-service")}
          >
            <FaServicestack className="menu-icon" />
            Service
          </div>
        </div>
      )}
      <div
        className={`menu-item ${
          selectedManu === "myprofile" ? "selected-menu-item" : ""
        }`}
        onClick={() => handleMenuItemClick("myprofile")}
      >
        <FaUser className="menu-icon" />
        My Profile
      </div>
      {isProfileSubMenuVisible && (
        <div className="submenu">
          <div
            className={`submenu-item ${
              selectedSubmanu === "profile-update"
                ? "selected-submenu-item"
                : ""
            }`}
            onClick={() => handleSubMenuClick("myprofile", "profile-update")}
          >
            <FaNetworkWired className="menu-icon" />
            Profile Update
          </div>
          <div
            className={`submenu-item ${
              selectedSubmanu === "logout" ? "selected-submenu-item" : ""
            }`}
            onClick={openlogoutModal}
          >
            <FaProductHunt className="menu-icon" />
            Logout
          </div>
        </div>
      )}
      <div id="logoutModal" className="logoutModal">
        <div className="modal-content-logout">
          <div className="logout-content">
            <a href="#">
              <img src={logout} alt="Logo" />
            </a>
            <h3>Are you sure ?</h3>
            <p>Are you sure you want to logout?</p>
            <div className="logout-buttons">
              <button className="logoutt" onClick={handleLogout}>
                Logout
              </button>
              <button className="cancel" onClick={closeLogoutModal}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CommonPage;
