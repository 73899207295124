import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import loginVectorImage from "../../images/forgot-password.jpg";
import logo from "../../images/logo2.png";
import userIcon from "../../images/user-icon.png";
import "../login/login.css";

function ForgotPassword() {
  const [email, setEmail] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(
        `http://localhost:8080/forgot-password?email=${email}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || "Failed to initiate password reset");
      }

      toast.success(data.message, { autoClose: 2000 });
    } catch (error) {
      toast.error(`Error: ${error.message}`, { autoClose: 2000 });
    }
  };

  return (
    <div>
      <ToastContainer />
      <div className="login-container">
        <div className="left-container">
          <img
            src={loginVectorImage}
            alt=""
            style={{
              maxWidth: "100%",
              maxHeight: "440px",
              marginTop: "20vh",
            }}
          />
        </div>
        <div className="right-container">
          <div className="form-container">
            <div className="header-logo">
              <a href="#">
                <img src={logo} alt="Logo" />
              </a>
            </div>
            <div className="form-header">
              <h1>Forgot password</h1>
              <p>Please enter your email below to receive a password reset link.</p>
            </div>
            <div className="form-body">
              <div className="form-group-login">
                <label htmlFor="email">Email ID</label>
                <div className="input-group">
                  <input
                    type="email"
                    id="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  <div className="input-addon">
                    <img src={userIcon} alt="" />
                  </div>
                </div>
              </div>

              <div className="form-group-login">
                <button type="submit" className="common-btn" onClick={handleSubmit}>
                  Submit
                </button>
                <a href="/login" className="forgot-txt">
                  Go To Login
                </a>
              </div>
            </div>
          </div>
        </div>
        <div />
      </div>
      <div className="copyright">
        <span>
          © 2024 Sadguru Healthcare Services Pvt Ltd | All rights reserved.
        </span>
      </div>
    </div>
  );
}

export default ForgotPassword;
